.mg-accordion, .mg-accordion ul{
	list-style-type: none;
}
.mg-accordion .submenu{
	display: none;
}
.justify-items-evenly{
	justify-content: space-evenly;
}
.mg-flat li.dropdown{
	margin-left: -40px
}
.mg-flat .submenu li{
	margin-left: 0px;
}
.mg-flat span.toggler{
	float: right;
}
.mg-flat a{
	text-decoration: none;
	display: block;
}
.mg-flat li.dropdown a{
	padding: 10px 5px;
	border:1px solid #ccc;
	background: #eee
}
.openItem span.toggler{
	transform: rotate(180deg);
	color: #fff;
}
.toggler{
	transition: all 1s;
    position: absolute;
    right: 30px;
    top: 40px;
}

