
@media (max-width:1366px){
	/* -----------sidebar css ------------------*/
.menu_collapsed .sidebar {
    width: 0px;
}

.menu_collapsed span.menuarrow {
    opacity: 0;
}
.menu_collapsed .content-section-1 {
    margin-left: 0px;
}
.content-section-1 {
    margin-left: 0 !important;
}
/* --------------- */
}
/* //----------------------------Landscape CSS for devices------------------------- */

