.dropdown-tree > ul{overflow-y: auto;overflow-x: hidden;white-space: nowrap;}
.dropdown-tree li{list-style: none}
.dropdown-tree li > i {margin-left: 10px;width: 20px;height: 20px;text-align: center;position: relative;z-index: 1;font-size: 20px;top: 3px;}
.dropdown-tree li:hover{ background: #eee;}
.dropdown-tree li:hover ul { background: white; }
.dropdown-tree li:hover ul li:hover { background: #eee; } 
.dropdown-tree a{display: inline-block !important;padding: 5px 20px;clear: both;font-weight: 400;line-height: 1.42857143;color: #333;white-space: nowrap;text-decoration: none;background:transparent !important; position: relative;}
.dropdown-tree .arrow{position: absolute;margin-left: -15px;top: 50%; transform: translateY(-50%);}

.rtl-dropdown-tree{direction: rtl !important}
.rtl-dropdown-tree > ul{right: 0 ; left: unset; text-align: right}
.rtl-dropdown-tree .arrow{right: 6px}
.rtl-dropdown-tree li > i {margin-left: 0;margin-right: 10px;}





.dropdown-tree .btn.dropdown-toggle {
    display: block;
    width: 100%;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    min-height: 52px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
}
.dropdown-tree .dropdown-toggle::after {
    position: absolute;
    right: 19px;
    top: 25px;
}
.dropdown-tree .dropdown-menu.show {
    display: block;
    right: 0;
	z-index: 9999;
}
.dropdown-tree li ul {
    padding-left: 60px;
}