html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  margin: 0;
  padding: 0;
}
.wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows:auto 1fr auto;
  height: 100vh;
  background-color: #F4F3F7;
  color: #444;
  overflow: auto;
}
.head,
.foot {
  position: sticky;
  top: 0;
  font-size: 1.2rem;
  z-index: 9;
}
.foot {
  top: auto;
  bottom: 0;
}
/* .boxcontent {
  position: relative;
} */
.content-scroll {
  position: absolute;
  left: 0;
  top: 70px;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: auto;
  overflow-y: auto;
}



