/* Global Styles */
/* @import url("https://cdn.datatables.net/1.10.16/css/jquery.dataTables.min.css"); */
@import url("https://cdn.datatables.net/buttons/2.3.6/css/buttons.dataTables.min.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
/* @import url("https://www.gstatic.com/charts/loader.js"); */


html,
body,
html body {
    font-family: 'Roboto', sans-serif;
}

.content-section {
    min-height: calc(100vh - 142px);
}

/* ------------------------------------------------------------self made css classes ------------- */

.text-light {
    color: #fff;
}

li {
    list-style: none;
}

.text-start {
    text-align: start !important;
}

.image-slider-main .main-inner {
    margin-left: 0px !important;
}

/* ----------------bredcumps css------------ */
.page_title_cstms ul.link_current {
    display: flex;
    gap: 18px;
    padding: 0;
    margin: 0;
    align-items: center;
}

.cstmbreadcum.paddingmng-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
}

.page_title_cstms {
    display: flex;
    gap: 18px;
    background-color: #e3e2e6;
}

.link_current li,
.backbtns {
    font-size: 14px;
    font-weight: 500;
}

.link_current li span {
    font-weight: 500;
}

.link_current .home a {
    color: hsl(0, 0%, 0%);
    font-weight: 500;
}

.page_title_cstms a.backbtns:hover {
    text-decoration: none;
}

.page_title_cstms a.backbtns:hover span {
    text-decoration: underline;
}

.page_title_cstms a.backbtns .mat-icon {
    height: unset;
    line-height: 10px;
    font-weight: 500;
}

.page_title_cstms a.backbtns span {
    color: #005fae;
    font-weight: 500;
}

.page_title_cstms ul.link_current li:before {
    content: "-";
    position: absolute;
    top: 0;
    left: -11px;
    color: #005fae;
}

.page_title_cstms ul.link_current li:first-child:before {
    content: "";
}

.page_title_cstms ul.link_current li {
    position: relative;
}

.page_title_cstms ul.link_current {
    display: flex;
    gap: 18px;
    padding: 0;
    margin: 0;
    align-items: center;
}

/* --------------------------root start ----------------------- */
:root {
    --primary: #005FAE;
    --secondary: #ffffff;
    --tertiary: #d23c45;
    --text-dark: #000;
    --border: #C3C6CF;
    --border-table: #43474E;
    --border-dark: #74777F;
    --header-bg: #EEEDF1;
}

/* ------------------------root end--------------- */
/* --------buttons custom css */
.update-btn {
    padding: 8px 24px;
    font-size: 14px;
    border-radius: 100px;
    border-width: 1px;
}

.description {
    width: 16%;
}

.cancel-btn {
    border-color: var(--border-dark);
    color: #005FAE;
    background-color: #fff;
    font-size: 14px;
    margin-left: 10px;
    border: 1px solid;
}

.save-btn {
    color: #fff;
    background-color: #005FAE;
}

.volant-toggle .mdc-switch__icons{
    display: none!important;
}

/* --------buttons custom css */
.table-responsive mat-header-row {
    background: #e3e2e6;
}

.table-responsive {
    height: calc(100vh - 262px);
}

.fullfillment-table .table-responsive {
    height: calc(100vh - 425px);
}

/* --------------------------search tabs start custom ------------------------- */
#shiprateVM .modal-dialog {
    max-width: 650px;
}

.mat-card-custom {
    box-shadow: none !important;
    border: 1px solid var(--border) !important;
}

/* --------------------------search tabs start custom ------------------------- */
table thead {
    background-color: var(--header-bg) !important;
    position: sticky;
    top: 0;
    z-index: 8;
}

.page_title_cstms a.backbtns {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.page_title_cstms a.backbtns:hover {
    text-decoration: none;
}

.page_title_cstms a.backbtns:hover span {
    text-decoration: underline;
}

.page_title_cstms a.backbtns .mat-icon {
    height: unset;
    line-height: 10px;
    font-weight: 500;
    color: #005fae;
}

.page_title_cstms a.backbtns span {
    color: #005FAE;
    font-weight: 500;
}

ul.link_current li span {
    color: #0d0d0d;
    font-size: 14px;
    font-weight: 500;
}

ul.link_current li a {
    color: #005FAE;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

/* ------------------------------------------------------------------------ */


body {
    background-color: #f4f3f7 !important;
}

body.mat-h2,
body.mat-title,
body.mat-typography h2,
.page-title h2 {
    font-size: 1.3rem;
    margin-bottom: 0;
}


a {
    cursor: pointer;
    cursor: hand;
}

button {
    background-color: #eee;
    border: none;
    padding: 2px 15px;
    border-radius: 4px;
    cursor: pointer;
    cursor: hand;
}


button:disabled {
    background-color: #eee;
    color: #aaa;
    cursor: auto;
}

.sidebar .navigation li a.active {
    background: #d23c45;
    color: #fff !important;
}

/* Navigation link styles */
nav a {
    padding: 5px 10px;
    text-decoration: none;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
    background-color: #eee;
    border-radius: 4px;
}

nav a:visited,
a:link {
    color: #607D8B;
}

nav a:hover {
    color: #039be5;
    background-color: #CFD8DC;
}

nav a.active {
    color: #039be5;
}

.gap-3 {
    gap: 20px;
}

/* everywhere else */


.white-box p {
    margin-bottom: 0;
}

.white-box {
    margin-bottom: 1.875rem;
    border: none;
    box-shadow: 0 2px 18px 1px rgba(49, 53, 72, .1);
    border-radius: .45rem;
    padding: 20px;
}

.h_second_1 {
    margin-top: 20px;
}

.form-control+.input-error {
    margin-bottom: 10px;
    font-size: 0.6rem;
}

.page-title.mb-4 {
    margin-bottom: 15px !important;
}

/* 25 nov integration */
/*************************/

img {
    max-width: 100%;
}


h1,
h2,
h3 {
    color: #333;
}

a,
a:hover {
    text-decoration: none;
}

.m-tb-40 {
    margin: 40px 0;
}

.p-0 {
    padding: 0;
}

.btn.sq-btn {
    border-radius: 0.35rem;
    height: 22px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 10px;
    line-height: 3px;
}

.btn.round-btn {
    border-radius: 25px;
    box-shadow: 0px 6px 24.55px 0.45px rgb(173 171 172 / 35%);
    height: 23px;
    padding: 4px 10px;
    margin-left: 10px;
    font-size: 0.6rem;
}

.btn i {
    margin-right: 3px;
}

.form-control:focus {
    border-color: #ce2026;
    box-shadow: none !important;

}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

input[type=checkbox],
input[type=radio] {
    height: 15px;
    width: 12px;
    margin-top: 0;
    position: relative;
    top: 2px;
}

input[type=radio].PoRec,
.PoRec {
    height: 15px;
    margin-top: 0;
    position: relative;
    top: 1px;
}


.margin-0 {
    margin: 0 !important;
}

.white-box.no_bg label.type_label {
    width: 100px;
    justify-content: left;
}

.form-check-label {
    margin-left: 15px;
}

.form-check-label:hover,
.highlighted {
    background-color: #4285f4 !important;
    color: white !important;
}

label.type_label {
    width: 100px;
}

.color-setting {
    margin: 40px 0;
}


:focus {
    outline: 0;
}

[type=search] {
    outline-offset: 0;
}

.h2,
h2 {
    font-size: 1.8rem;
}



/**************** breadcrumb css ***************************/

.bread-crumb a {
    color: #6f717d;
}

.bread-crumb span.active {
    color: #3592e0;
}


/************* header css *******************/
header {
    width: 100%;
    position: fixed;
    z-index: 99;
    right: 0;
    left: 0;
    top: 0;
}

mat-header-row {
    z-index: 2 !important;
}

.header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #d23c45;
    height: 70px;
}

.search-input {
    position: absolute;
    left: 260px;
    background: #fff;
    right: 0;
    top: 0;
    z-index: 9;
    margin-top: 0;
    border-left: 1px solid #E4E5EC;
    display: none;
    bottom: 0;
}

.form-fields-custom .height-change .mat-mdc-form-field-flex {
    align-items: center;
}

.header-right {
    flex-grow: 1;
    padding: 0 20px;
}


span.avatar {
    height: 42px;
    width: 42px;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 20px;
}

.header-right .navbar-nav {
    flex-direction: row;
    align-items: center;
}

.header-right .text-right .navbar-nav {
    justify-content: flex-end;
}

.header-main li.site-logo {
    text-align: center;
}

.header-right .nav-item {
    padding: 0 15px;
}

.header-right i {
    font-size: 1.3rem;
}

.header-right .dropdown-user a {
    color: #212529;
    padding: 0 5px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}

.fs-11 {
    font-size: 10px;
}

.header-right .dropdown-user a.dropdown-user-link {
    display: inline-block;
    color: #fff;
}

.search-input input {
    width: 100%;
    height: 100%;
    padding: 10px 41px;
    border: 0;
}

.search-input-close {
    position: absolute;
    right: 20px;
    top: 32px;
    cursor: pointer;
    padding: 0 10px;
}

.search-input-close i {
    color: #747475;
}

/***************** end header css *****************/

/************* sidebar css *********/

.sub-menu.active {
    display: block;
}

.sidebar .navigation li a {
    padding: 8px 20px 8px 13px;
    display: block;
    overflow: hidden;
    color: #1A1C1E;
    transition: all .2s ease;
    white-space: nowrap;
    font-size: 12px;
}

.sidebar .navigation>li:hover>a {
    padding-left: 24px;
    color: #d23c45;
}

.sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar .navigation>li>a i,
.sidebar .navigation>li>a img {
    margin-right: 11px;
    font-size: 14px;
}

.sidebar {
    position: fixed;
    top: 133px;
    height: calc(100% - 56px);
    width: 280px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 30%);
    transform: translate3d(0, 0, 0);
    transition: .5s ease all;
    background: #EEEDF1;
    overflow: auto;
    z-index: 9;
}

.sidebar .navigation>li>a img {
    width: 16px;
}

.sidebar ul li .sub-menu li:hover a {
    color: #d23c45;
}


.sidebar-icon:hover {
    color: red;
}

.sidebar .navigation>li>a span.menu-title {
    transition: all .2s ease;
    font-weight: 500;
}

.sidebar .sidebar-ank {
    background: #e3e2e6;
}

.sidebar ul li .sub-menu {
    font-size: 0.85rem;
}

.sidebar ul li .sub-menu a {
    padding-left: 48px;
}

.sub-menu {
    display: none;
}

.sidebar .navigation li a.active:hover {
    color: #fff;
}

.menuarrow {
    position: absolute;
    right: 0;
    font-size: 1rem;
}

.sidebar ul li .sub-menu i {
    width: 23px;
}

/***** sidebar scroll *********************************/
/* ------------sidebar responsive css------- */
.menu_collapsed .sidebar {
    width: 0;
    /* left: -280px; */

}

.menu_collapsed .content-section-1 {
    margin-left: 0px;
    transition: 0.5s;
}

/* ------------sidebar responsive css------- */

/* width */
.sidebar::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
    background: #a3a4a7;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.table td,
.table th {
    padding: 5px !important;
}

span.tooltipWrap {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    display: block;
}

.purchaseOrder table tr td:nth-child(3) {
    width: 10%;
    max-width: 300px;
}


.company-table table tr td:nth-child(7) {
    width: 10%;
    max-width: 300px;
}

.ng-dirty .table-responsive {
    overflow-x: unset;
}

.table-responsive.actTable {
    overflow-x: auto;
}

span.tooltipWrap+span.tooltips {
    display: none;
    position: absolute;
    top: -250%;
    width: 350px;
    left: 0;
    background: #000000b0;
    color: #fff;
    padding: 5px;
    z-index: 9;
    cursor: pointer;
    border-radius: 10px;
}


.customerTextarea-setting {
    min-height: 60px;
    width: 100%;
    font-weight: 500;
}

.align-check input {
    top: 4px;
}

span.tooltipWrap.tooltip-icon {
    width: fit-content;
}

span.tooltipWrap:hover+span.tooltips {
    display: inline;
}

span.tooltipWrapCost+span.tooltips {
    display: none;
    position: absolute;
    width: 157px;
    left: -22px;
    background: #000000b0;
    color: #fff;
    padding: 5px;
    z-index: 9;
    cursor: pointer;
    border-radius: 10px;
}

span.tooltipWrapCost.tooltip-icon {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

span.tooltipWrapCost {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    display: block;
}

span.tooltipWrapCost.tooltip-icon {
    width: fit-content;
}

span.tooltipWrapCost:hover+span.tooltips {
    display: inline;
}


/******************************************************/

.content-section {
    margin-left: 275px;
    /* margin-top: 60px; */
    padding: 15px;
    transition: .3s ease all;
    backface-visibility: hidden;
}

.white-box {
    margin-bottom: 4px;
    border: none;
    box-shadow: 0 2px 18px 1px rgba(49, 53, 72, .1);
    border-radius: .45rem;
    padding: 3px 10px 3px 10px;
    background: #fff;
}

.card-desc h5 {
    font-size: 1.02rem;
}

.card-desc .white-box-title {
    margin-top: 10px;
    position: relative;
}

.p-tb-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.lg-text {
    font-size: 2.2rem;
}

.blue-text {
    color: #1e9ff2;
}

.blue-bg,
.btn.blue-bg {
    background: #1e9ff2;
    color: #fff;
}

.SeachButton {
    background: #ced5da;
    color: #fff;
}

.yellow-text {
    color: #ff8a3b;
}

.yellow-bg,
.btn.yellow-bg {
    background: #ff8a3b;
    color: #fff;
}

tr.part-category i {
    color: #28d094;
    font-size: 1.1rem;
}

.red-text,
.red-text i {
    color: #ff4962;
}

.green-text {
    color: #28d094 !important;
}

.red-bg,
.btn.red-bg {
    background: #ff4962;
    color: #fff;
}

.green-bg,
.btn.green-bg {
    background: #28d094;
    color: #fff;
}

.grey-bg,
.btn.grey-bg {
    background: #f4f5fa;
    border: 1px solid #e4e4e7;
}

.card-icon {
    font-size: 1.8rem;
    height: 70px;
    width: 70px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    line-height: 70px;
}

.white-box-title {
    margin-bottom: 5px;
    border-bottom: 1px solid #dad8d8;
}


.white-box-title h5 {
    color: #4c4a4a;
}

.bt-1 {
    border-top: 1px solid #eee;
}

.align-radio-margin input[type='radio'] {
    margin-bottom: 2px;
}

.setposition .dropdown-company button.btn.btn-outline-secondary.dropdown-toggle {
    height: unset !important;
    padding: 0;
}

.h_second_2 {
    padding-left: 10%;
}

.grey-sec-desc h6,
.grey-sec-title h5 {
    margin: 0;
}

/***** btns *****/

.btn-sm.sq-btn {
    height: auto;
    padding: 7px 12px;
    font-size: 0.6rem;
}

.choose-file-hidden {
    height: auto !important;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer !important;
}

.add-file {
    position: absolute;
    top: 0;
    bottom: 0;
    max-height: calc(100% - 20px);
    pointer-events: none;
}

.bt-dark-1 {
    border-top: 1px solid #dadada;
}

.darkred-bg,
.btn.darkred-bg {
    background-color: rgb(206, 32, 38);
    color: #fff;
}

.content-section-1 {
    margin-left: 279px;
    transition: 0.5s;
}

/********************************** company herirachy view***************************/

.mg-flat li.dropdown a {
    position: relative;
    background: #f8f9fd;
    border-radius: 8px;
    border: 1px solid #cdcfce;
    padding: 25px 30px;
    margin: 20px 0px;
    color: #333;
}

.mg-flat li.dropdown a.openItem {
    color: #3592e0;
}

.mg-flat li.dropdown a i {
    color: #28d095;
}

.company-info {
    margin: 0;
    font-size: 14px;
    color: #999;
}

.company-logo {
    position: absolute;
    left: 0;
}

.company-logo img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.company-info-main {
    padding-left: 70px;
    ;
}

mat-icon.mat-icon.notranslate.print-icon-image.ng-tns-c4140695153-1.material-icons.mat-ligature-font.mat-icon-no-color {
    width: 40px;
    height: 40px;
}

.print-icon-image::before {
    content: '';
    position: absolute;
    background-image: url(../src/assets/image/print-icon.png);
    height: 38px;
    width: 38px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 0px;
    right: 2px;
    cursor: pointer;
}

#SelectPrinter .modal-content {
    background-color: #E9E8EB !important;
}

/*************************** table css ****************************/

table,
table.dataTable,
table.dataTable.no-footer {
    width: 100%;
    text-align: center;
    border: 1px solid #ddd;
    margin: 0px 0 20px;
    min-width: 100%;
}

.table td,
.table thead th,
table td,
table thead th {
    vertical-align: middle;
}

table.dataTable {
    float: left;
}

table.dataTable thead th,
table thead th {
    background: #eee;
    border-color: #ddd;
    font-size: 14px;
    color: #43474E;
    font-weight: 500;
}

table.dataTable.no-footer {
    border-color: #ddd !important;
}

td img {
    max-height: 40px;
    border-radius: 50%;
    border: 1px solid #1e9ff2;
    width: 40px;
    height: 40px;
}

td i {
    margin: 0 2px;
}

table.no-border {
    border: 0;
    min-width: 40%;
    width: auto;
}

table.no-border td {
    padding: 10px 0;
}

.dataTables_length select {
    margin: 0 5px;
}

.content-section .dataTables_wrapper .dataTables_paginate .paginate_button {
    border: 1px solid #d23c45;
    color: #d23c45 !important;
    margin: 0 3px;
}

.content-section .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.content-section .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.content-section .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: #d23c45;
    border-color: #d23c45;
    color: #fff !important;
}

.content-section .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.content-section .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.content-section .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #d4d3d3fc !important;
    border: 1px solid #d4d3d3fc;
    background: transparent;
    box-shadow: none;
}

/************ company-list *************/
/* -----------------------put away------------------- */
mat-card-content.mat-mdc-card-content.card_cstm-mngsd.pickListBody {
    padding-bottom: 0px;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: #E9E8EB !important;
}

.mdc-dialog .mdc-dialog__content {
    padding: 15px 24px !important;
}

.top-bredcumps-second {
    position: sticky;
    /* top: 70px; */
    z-index: 2;
}

/************ footer css ***********/

/* .footer {
    background: #f4f3f7;
    padding: 0;
    bottom: 0;
    position: fixed;
    right: 47%;
    z-index: -1;
} */

/***/
.flex-grow-1 {
    flex-grow: 1;
}

/**** part page css ********/

.addpart-image {
    position: absolute;
    left: 15px;
    width: 130px;
    height: 130px;
}

.addpart-image img {
    height: 85px;
    max-height: initial;
}

.addpart {
    padding-left: 160px;
}

.upload-partid {
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.upload-partid+button {
    position: absolute;
    top: 0;
    left: 15px;
    right: 0;
    pointer-events: none;
    width: calc(100% - 30px);
}

.question-detail {
    position: relative;
    padding-right: 50px;
}

.qus-action {
    position: absolute;
    right: 0;
    top: -3px;
    font-size: 1.1rem;
    cursor: pointer;
}

.dataTables_wrapper .dataTables_filter input {
    height: 25px;
}

.upload-partid {
    min-height: 54px;
}

.category-title {
    background: #eee;
    padding: 10px 15px;
    border-radius: 6px;
}

/********* product css *****/

.category-detail {
    background: #f4f5fa;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid #e3e4e4;
}

.category-detail .btn-sm.sq-btn {
    height: auto;
    padding: 7px 12px;
    font-size: 0.6rem;
}

.category-detail .btn i {
    margin: 0;
}

.part-detail {
    background: #f1f1f1;
    padding: 10px;
    margin: 10px;
}

.part-detail {
    background: #fff;
    padding: 10px 20px;
    margin: 10px 20px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-size: 0.9rem;
}

.caterory-parts {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #e3e4e4;
}

.caterory-parts:empty {
    margin: 0;
    padding: 0;
    border: 0;
}

.qus-label {
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #f7f7f7;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    font-weight: 600;
    min-height: 52px;
    margin-bottom: auto;
    padding-left: 20px;
    line-height: 52px;
}

.modal-body {
    padding: 1.4rem;
}

.order-item {
    display: none;
}


/*****************************/


.table-partdetail,
.table-partname {
    display: none;
}

table.order-detail th,
table.order-detail td {
    padding: 5px;
    font-size: 0.8rem;
}

.part-category {
    margin-bottom: 10px;
    color: #333;
}

td.part-category i {
    color: #28d094;
    font-size: 1.1rem;
}

.order-detail td i {
    margin-right: 5px;
    cursor: pointer;
    font-size: 15px !important;
}

.table td,
.table th {
    border-bottom: 1px solid #dee2e6;
}

.order-detail thead th {
    border: 1px solid #dee2e6;
}

.table-partdetail td {
    padding-left: 40px;
}

.qus-title {
    color: #333;
    border-bottom: 1px solid #eee;
    font-weight: bold;
}

.qus {
    font-size: 0.6rem;
}

.white-box-title h6 {
    font-size: 0.6rem !important;
}

.white-box.ProductsList table tbody tr td:first-child {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px !important;
}

.white-box.ProductsList table tbody tr td:nth-child(2) {
    max-width: 550px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 550px !important;
}

.order-detail {
    box-shadow: 0 2px 18px 1px rgba(49, 53, 72, .1);
    background: #fff;
}

.order-detail-main {
    overflow: auto;
}

.uplod-logo {
    max-height: 60px;
    margin-left: 0;
}

.modal-open {
    max-height: 100vh;
}

.grey-sec-title .btn {
    margin-left: 5px;
}

.grey-sec-title .btn i {
    margin: 0;
}

.herirchylavel2>.grey-sec-title h5 {
    font-size: 0.9rem;
}

.herirchylavel3>.grey-sec-title h5 {
    font-size: 0.8rem;
}

.herirchylavel4>.grey-sec-title h5 {
    font-size: 0.6rem;
}

.herirchylavel1 {
    padding: 5px 10px;
    border: 1px solid #e4e4e7;
    border-radius: 5px;
    margin-top: 20px;
}

button.no-btn {
    padding: 5px;
    background: transparent;
}

.sidebar .navigation>li>a .menuarrow i {
    font-size: 0.8rem;
}

.mat-h5,
.mat-typography h5 {
    font-size: 0.7rem;
    margin-bottom: 0;
}

.table-parent {
    overflow: auto;
}

.category-detail .btn {
    margin-left: 5px;
}

header .navbar-nav .dropdown-menu {
    position: absolute;
}

.herirchylavel1>.grey-sec-title>.row h5 {
    font-size: 14px;
}

button.btn.close {
    position: absolute;
    right: 10px;
    top: 10px;
    outline: 0;
    border: 0;
    text-shadow: none;
    color: #fff;
}

h4.modal-title {
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
}

.dropdown-company .dropdown-menu {
    width: 100%;
    font-size: 0.8rem !important;
}

.btn-outline-secondary {
    height: calc(1.7em + .75rem + 1px);
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #ced4da !important;
    font-size: .8rem !important;
}

/* set height and with of heade logo on 04-01-2021 */
.hrdLogoSize {
    height: 45px;
    width: auto;
}

.white-box .form-control {
    font-size: 0.6rem !important;
    height: 20px;

}

.white-box textarea.form-control {
    height: 60px;
}

.form-group.required .control-label:after,
.required .customLabel:after {
    content: "*";
    color: red;
}

.select-company button {
    font-size: 14px !important;
}

.select-company .treeview-item {
    padding: 5px 0
}

.select-company .treeview-item label {
    font-size: 13px;
    color: #333;
    margin: 0 4px 0 0;
}


.select-company .treeview-item .treeview-item {
    margin-left: 1rem;
}

.part-cost-block {
    position: relative;
}

.part-cost-block .form-control {
    padding: .375rem 1.9rem;
}

.part-cost-block span.dollar {
    position: absolute;
    left: 10px;
    bottom: 3px;
}

.custom-table table,
table.dataTable,
table.dataTable.no-footer {
    margin: 0px;

}




/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    z-index: 1051;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.coloumn-hide {
    display: none;
}

.err-fontSize {
    font-size: 0.6rem;
}

.apply-btn {
    height: 28px !important;
    margin-left: 0px !important;
    min-width: 16px !important;
    padding: 4px 12px 18px 12px !important;
}

.discount-amt {
    font-size: 10px;
    font-weight: bold;
}

.per-span {
    position: absolute;
    margin-left: -17px;
}

.dollar-span {
    position: absolute;
    margin-left: 10px;
}

.order-del {
    color: #ff4962 !important;
}

.showBtn {
    visibility: hidden;
}

.hoverEffect:hover .showBtn {
    visibility: visible;
}

.hoverEffect .fa-trash-alt {
    font-size: 0.7rem !important
}

.inputShow,
.hidden {
    display: none;
}

.normalShow {
    display: inline;
}

.qty {
    width: 70px;
    text-align: center;

}

.form-control.odr-qty {
    width: 70px !important;
    text-align: center !important;
    margin: auto;
}

.form-control.inputShow {
    width: 120px !important;
    text-align: center !important;
    margin: auto;
}

.p-addPart {
    width: 50px !important;
    padding: 0px 2px 0px 0px !important;
    height: 20px !important;
}

button.p-addPart+span.showBtn i {
    font-size: 10px !important;
    margin-left: 5px;
}

table.order-detail tr.part-category td:first-child {
    width: 400px;
}

div#addPart .modal-header.info-popup-header,
div#addpart .modal-header.info-popup-header {
    padding: 3px 8px;
    display: flex;
    align-items: center;
}

div#addPart .modal-header.info-popup-header h5#exampleModalLabel,
div#addpart .modal-header.info-popup-header h5#exampleModalLabel {
    margin-bottom: 0 !important;
}

div#addPart .modal-header.info-popup-header button.close span,
div#addpart .modal-header.info-popup-header button.close span {
    text-shadow: none;
}

div#addPart .modal-header.info-popup-header button.close span:hover,
div#addPart .modal-header.info-popup-header button.close span:hover {
    color: #f00;
}

div#addPart .modal-body .form-group label,
div#addpart .modal-body label,
div#addpart .modal-body .form-group label,
div#addpart .modal-body label {
    font-size: 0.6rem;
    font-weight: bold;
}

div#addPart .modal-body .form-group select option,
div#addpart .modal-body .form-group select option {
    width: 200px;
    white-space: pre-wrap;
    display: block;
}

div#addPart .modal-body,
div#addpart .modal-body {
    padding: 10px;
}

.order-detail-main table.order-detail tbody tr.table-partname span.PartName {
    width: 310px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-fix textarea {
    border: 1px solid #000;
}

span.PartName.tooltipWrap+span.tooltips,
.order-detail-main span.tooltipWrap+span.tooltips {
    left: 10px;
    white-space: pre-wrap;
}

.order-detail-main span.tooltipWrap.partDetails {
    width: 310px;
}

.order-detail-main input.form-control,
.order-detail input.form-control,
.order-detail select.form-control {
    font-size: 0.8rem !important;
    height: 23px !important;
}

.OrderPartsDetails tr.hoverEffect span,
tr.hoverEffect.OrderPartsDetails td,
tr.hoverEffect.OrderPartsDetails span,
tr.hoverEffect.OrderPartsDetails td span.onEditCurrText {
    font-size: 0.6rem !important;
    font-weight: 400 !important;
}

tr.hoverEffect.OrderPartsDetails td span.onEditCurrText {
    display: block;
}

tr.hoverEffect.customParts span.PartName.tooltipWrap {
    width: 190px;
}

tr.hoverEffect.OrderPartsDetails+tr.table-partdetail select.form-control {
    padding: 2px;
    height: 23px !important;
    font-size: 0.6rem;
}

/*   9/2/21  */

.discountSetup .col-sm-4 .row .col-sm-6:first-child {
    font-weight: 600;
    text-align: left !important;
    padding: 0.7rem 0.5rem;
}

.discountSetup .col-sm-4 .row .col-sm-6:nth-child(2) {
    line-height: 3.5;
}

input.CostAmount {
    border: 1px solid #1e9ff2;
}

.discount-amt {
    font-size: 0.9rem;
    color: #f00;
}

.finalAmt {
    color: #1e9ff2;
}

.width-100 {
    width: 100px !important;
}

.width-60 {
    width: 60px !important;
    margin: 0px !important;
}

.width-100_1 {
    width: 100px !important;
    margin: 0px !important;
}

.width-150 {
    width: 150px !important;
    margin: 0px !important;
}

tfoot.footOrder {
    background-color: #eee;
}

tfoot.footOrder tr td {
    border-right: 1px solid #dee2e6;
}

tfoot.footOrder tr:last-child td {
    color: #0fa506;
    font-size: 0.6rem;
}

tfoot.footOrder tr td input[type="text"] {
    width: 100px;
    border: 1px solid #ccc;
    margin-left: 4px;
    border-radius: 5px;
    text-align: center;
}

tfoot.footOrder tr td .dollar {
    margin-left: 10px;
}

tfoot.footOrder tr td .discount {
    background-color: #eee;
    padding: 3px;
}

tfoot.footOrder tr select {
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #0b7706;
    max-height: 19px;
    min-height: 19px;
}

.tr-bg-clr-none {
    background: #eeeeee !important;
    border: none !important;
}

.double-border {
    border-bottom: ridge !important;
}

table.dataTable thead tr th,
table.dataTable tbody tr td {
    text-align: left !important;
    white-space: nowrap;
}

table.dataTable tbody th,
table.dataTable tbody td {
    padding: 6px 10px;
}

table.dataTable thead th {
    padding: 6px 10px !important;
}

table.dataTable thead tr th:last-child,
table.dataTable tbody tr td:last-child {
    width: 75px;
}

.width-75 {
    width: 75px !important;
}

.display-none {
    display: none;
}

.info-popup-header,
.modal-title {
    background-color: #3592e0;
    color: #fff;
}

.info-popup-title {
    color: white;
}

.border-none {
    border: 0 !important;
    font-weight: bold !important;
}

section.forgetPassword input.form-control[type=password] {
    height: 52px;
    background-color: #e8f0fe;
}

section.forgetPassword input.form-control[type=password]:focus {
    border: 1px solid #f00;
    box-shadow: 0 0 3px #f00;
}

section.forgetPassword .color-setting {
    margin: 0;
}

span.onEditCurrText {
    display: inline-block;
    font-weight: bold;
}

span.onEditCurrTextProduct {
    display: inline-block;
}

.order-detail-main table.order-detail thead tr th:last-child {
    text-align: right;
}

.order-detail-main table.order-detail tfoot.footOrder tr td:last-child {
    text-align: right;
    padding-right: 5px !important;
}

.order-detail-main table.order-detail tbody tr td:last-child {
    text-align: right;
    padding-right: 5px !important;
}

.order-detail-main table.order-detail tbody tr td:nth-child(1) {
    text-align: left;
}

.customicon {
    width: 17px;
    height: auto;
    margin: 2px;
}

.addpart .btn.blue-bg.round-btn.part-category {
    border: 2px solid #1e9ff2;
    background: #ffffff00;
    color: #1e9ff2;
    font-weight: bold;
}

.white-box.partList table tr th,
.white-box.partList table tr td {
    font-size: 0.7rem;
    padding: 3px 10px;
}

.white-box.partList table tr td:nth-child(4) {
    white-space: nowrap;
}

.partPage .form-group {
    margin-bottom: 5px;
}

.label1 {
    font-weight: bold;
    display: inline-block;
    margin-bottom: -0.5rem;
}

.forbold {
    font-weight: bold
}

.solid {
    border-style: groove;
    height: 120px;
}

.white-box .control-label {
    font-size: 0.6rem;
    margin-bottom: 0px;
    font-weight: 600;
}

.white-box .form-group {
    margin-bottom: -1px;
}

.white-box .f13 {
    font-size: 0.6rem !important;
}

.white-box .dropdown button {
    padding: 2px 14px 2px 6px;
    height: 22px;
    color: #8c8888 !important;
    font-weight: 600;
    font-size: 0.6rem !important;
}

section#new-layout .col-3.width_cmn_type {
    max-width: 160px;
}

.downloadicon {
    font-size: 24px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    cursor: pointer;
}

.table-responsive .order-detail {
    border-bottom: 1px solid #d6d6d6;
}

.lodingtext p {
    color: white;
    font-size: 18px;
    position: relative;
    top: 55px;
    left: -34px;
}

.fetchedDetails {
    border: 1px solid #ccc;
    padding: 5px 10px 0 10px;
    font-size: 12px;
    height: 100%;
}

.fetchedDetails ul {
    padding-left: 0;
    margin-bottom: 0;
}

.fetchedDetails ul li.d-flex {
    align-items: baseline;
    margin-bottom: 0px;
    line-height: 1.1;
    font-size: 0.6rem;
}

.fetchedDetails ul li.d-flex label {
    min-width: 127px;
    font-weight: 600;
    font-size: 0.6rem;
    margin-bottom: 3px;
}

.white-box.purchasepage .form-group {
    margin-bottom: 3px;
}

hr.formSeperate {
    border-top: 1px solid rgb(0 0 0 / 48%);
}

.purchaseOrder table {
    margin-top: 8px;
    margin-bottom: 8px;
}


table tr:first-child td {
    font-weight: bold;
    white-space: nowrap;
}

.table-order-part table tr:first-child td, .ready-to-pic_table table tr:first-child td {
    font-weight: bold;
    white-space: normal;
}

table tr:not(:first-child) td {
    font-size: 14px;
}

table tbody th {
    font-size: 12px;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
    padding: 5px !important;
}

.EditWidth {
    width: 150px;
}

.bread-crumb a,
.bread-crumb span {
    font-size: 0.6rem;
}

.table-responsive.distable span.tooltipWrap+span.tooltips {
    width: 960px;
    left: -270px;
}

.fontvalidation {
    font-size: 9px;
}

.part1-cost1-block1 span.dollar1 {
    position: absolute;
    left: 10px;
    bottom: 2px;
}

.fontdata {
    font-size: 10px;
}

.wsnw {
    white-space: nowrap;
}

.menu-toggle {
    padding: 10px 20px 10px 1px !important;
}

.shiped .mat-form-field-label-wrapper,
.package .mat-form-field-label-wrapper {
    height: 0.5px;
    overflow: hidden;
    padding: 0;
    background: #74777f;
    top: -8px;
    position: absolute;
    left: -6px;
    width: 55px;
}

.shiped .mat-form-field-flex:hover .mat-form-field-label-wrapper,
.package .mat-form-field-flex:hover .mat-form-field-label-wrapper {
    height: 2px !important;
    transition: 0.2s;
}

ul.value-section.added-row .shiped .mat-form-field-label-wrapper,
ul.value-section.added-row .package .mat-form-field-label-wrapper {
    height: 0.5px;
    overflow: hidden;
    padding: 0;
    background: #74777f;
    top: -18%;
    position: absolute;
    left: -6px;
    width: 55px;
}


button.arrow-updown {
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #c9c9c9;
    padding: 0;
    margin: 0 1px;
}

button.arrow-updown[disabled] {
    background: #e3e3e3;
}

button.arrow-updown i {
    font-size: 13px !important;
    margin: 0;
    color: #2e2e2e;
}

button.arrow-updown:hover {
    background: #9d9d9d;
}

html,
body,
html body,
* {
    font-family: 'Roboto', sans-serif;
}

.white-box .control-label,
.fetchedDetails ul li.d-flex label {
    font-weight: 350;
}

.table td,
.table th {
    padding: 1px !important;
}

table.dataTable thead tr th,
table.dataTable tbody tr td {
    padding: 1px 5px !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
    padding: 2px 5px !important;
}

.ng-star-inserted td {
    font-weight: 400 !important;
    font-size: 14px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.2em 0.4em !important;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    cursor: hand;
    color: #333333 !important;
    border: 1px solid transparent;
    border-radius: 2px;
    font-size: .7rem;
}

.datatable_filter label,
.datatable_length label,
.dataTables_length label {
    font-size: 12px !important;

}

div.datatable_filter input {
    max-width: 110px;
    width: 100%;
    margin-left: 10px;

}

.white-box-title.border-0.margin-0 img#uplod-img {
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 10px;
}

.card-desc .color-setting {
    margin: 10px 0px;
}

.card-desc .color-setting table.no-border.text-left {
    margin-top: 0;
}

.card-desc .color-setting table.no-border.text-left tr td {
    padding: 4px;
}

label.checkbox-inline input {
    vertical-align: top;
}

.card-desc .col-md-4.p-0 .dropdown-company button.btn.dropdown-toggle.btn-outline-secondary {
    position: relative;
    left: -45px;
}

.card-desc label {
    margin-bottom: 0;
    /* font-size: 10px; */
}

.card-desc h5,
.card-desc .col-md-2.mb-4.required {
    font-size: 12px;
}

.card-desc .color-setting table.no-border.text-left tr td input {
    float: left !important;
    margin: 0 20px 0px 0px !important;
}

.ml-4.part-cost-block {
    display: inline-block;
    width: 100%;
}

.white-box textarea.form-control {
    height: 45px;
    max-width: 1036px;
    margin-top: 0px;
}

input[type="radio"] {
    width: 10px;
}

.modal-body .form-control {
    font-size: 0.6rem !important;
    height: 23px;
}

.newsmallddl {
    height: 23px !important;
    font-size: 0.6rem !important;
    padding: 0.1rem .75rem !important;
}

h4.modal-title.darkred-bg {
    font-size: 13px !important;
}

.fileuploadclass {
    padding: 0rem 0rem !important;
}

/* Akil End */
/* Salman khan css */
.orderNumberCustomer {
    margin: 12px;
    margin-right: 31px;
}

/* // */
span.tooltipWrapCustom {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 195px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    display: block;
}

.form-control {
    padding: .175rem .35rem !important;
    border: 1px solid #a2a6ab !important;
    color: #8c8888 !important;
    font-weight: 600 !important;
}

.dataTables_info {
    font-size: 0.6rem !Important;
}

.dataTables_length,
.dataTables_filter {
    margin-bottom: 5px;
    font-size: 0.6rem !important;
}

.dataTables_wrapper label,
.dataTables_wrapper input,
.dataTables_wrapper select {
    font-size: 0.6rem !important;
    height: 22px !important;
}

.dataTables_scroll table tr td {
    -webkit-font-smoothing: antialiased;
}

.dataTables_wrapper.no-footer label {
    margin: 0;
}

/* new css */
.white-box.no_bg .heading_title,
.heading_title {
    border-bottom: 4px solid #cccccc4d;
    font-weight: 700;
    font-size: 13px;
}

.white-box.no_bg input.input_cmn,
input.input_cmn,
.white-box.no_bg select.input_cmn,
select.input_cmn {
    border-radius: 0.25rem;
    padding: .175rem .35rem;
    border: 1px solid #b2b2b2b3 !important;
    color: #000;
    font-size: 12px;
    width: 215px;
    min-height: 22px;
    height: 22px;
    font-weight: 500;
}

.white-box.no_bg textarea.form-control {
    border: 1px solid #b2b2b2b3 !important;
}

.submit-from.text-right button:hover {
    color: #fff;
}

.submit-from.text-right button i {
    color: #fff;
}

.form_group_cmn label,
.white-box.no_bg .control-label {
    font-size: 10px;
    width: 92px;
    color: #8a8a8a;
    font-weight: 400;
}

.white-box.no_bg button.btn.blue-bg.round-btn:hover {
    color: #fff;
}

.form_group_cmn {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.white-box.no_bg {
    box-shadow: none;
}

.sidebar .navigation li a {
    border-bottom: 1px solid #cccccc63;
}

.white-box.no_bg ngx-dropdown-treeview-select#dropdownfocus {
    width: 215px;
}

label.mr-1.control-label.type_label.label_type_fx {
    width: 25px;
}

label.mr-1.control-label.type_label.label_cmpny_fx {
    width: auto;
}

.col-3.width_cmn_type {
    max-width: 150px;
}

.col-3.width_cmn_type select#TCreateUpdate {
    min-width: 110px;
    height: 22px;
    min-height: 22px;
}

.col-3.width_cmn_company {
    max-width: 190px;
}

textarea.form-control.input_cmn.customer_notes {
    width: 86.3%;
}


span#openpart-1 i {
    position: relative;
    top: 2px;
}

.custom_checkbox {
    height: 11px !important;
}

.setBorder {
    border-width: 0 1px 0 0 !important;
}

.bb-1 {
    border-width: 0 1px 1px 0 !important;
}

.tfootnew .fix_ht {
    height: 20px;
    min-height: auto;
    font-size: .6rem;
}

.add-partcategory .green-bg i,
.add-partcategory .green-bg:hover {
    color: #fff;
}

.white-box .input_cmn_50 {
    width: 70.5%;
    min-height: 22px;
    border-color: #b2b2b2b3 !important;
}

.white-box .input_cmn_full {
    min-height: 22px;
    border-color: #b2b2b2b3 !important;
}

.white-box .input_cmn_full_100 {
    min-height: 25px;
    width: 88%;
    border-color: #b2b2b2b3 !important;
}

.form-group.form_group_cmn button:hover {
    color: #fff;
    background-color: #1594e6;
    cursor: pointer;
}

.removeBorder {
    border-width: 0 1px 0 0 !important;
}

td .bb-1 {
    border-bottom: 1px solid !important;
}

.part-category td {
    background: #dadada !important;
}

.w55 {
    width: 55%;
}

.white-box.no_bg input.input_cmn:focus,
.white-box.no_bg input.input_cmn_50:focus,
input.input_cmn:focus,
input.input_cmn_50:focus,
.white-box.no_bg select.input_cmn:focus,
.white-box.no_bg select.input_cmn_50:focus,
select.input_cmn:focus,
select.input_cmn_50:focus {
    border: 1px solid #8c8888 !important;
}

section#new-layout[_ngcontent-pav-c4] label[_ngcontent-pav-c4] {
    width: 164px !important;
}

section#new-layout .white-box.no_bg label.control-label {
    width: 164px;
}

section#new-layout .white-box.no_bg input.form-control.input_cmn.width-100,
section#new-layout .white-box.no_bg textarea.form-control.input_cmn {
    max-width: 86% !important;
}

.salesorder_hd .width_cmn_type {
    max-width: 180px;
}

.salesorder_hd .width_cmn_type label {
    width: 25px !important;
}

.salesorder_hd .width_cmn_type {
    max-width: 125px;
}

.salesorder_hd .orderno_sales {
    max-width: 250px !important;
    padding-left: 0;
}

input#discountdata {
    top: 2px;
    position: relative;
    height: 11px;
    width: 11px;
}

section#new-layout .form-group.form_group_cmn.position-relative {
    width: 500px;
}

section#new-layout label.mr-1.control-label.margin-minus.label_cmpny_fx {
    width: 67px;
}

section#new-layout input.form-control.col-md-6.positioned-0 {
    max-width: 300px !important;
}

.sales .form-group.form_group_cmn.required {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sales .form-group.form_group_cmn.required .control-label {
    flex-basis: 20%;
}

.sales .form-group.form_group_cmn.required .dropdown-company {
    flex-basis: 70%;
}

.sales .form-group.form_group_cmn.required .input-error {
    flex-basis: 100%;
    margin-left: 30%;
}

.order-detail-main table.order-detail thead tr th:last-child {
    padding-right: 5px !important;
}

section.content-section .w_auto {
    width: auto !important;
}

/* start css for new layout design 27 July */

.customInput,
.customInput-dropdown .dropdown,
.customInput-dropdown .dropdown .row-filter .form-control {
    border-radius: 0.25rem;
    border: 1px solid #b2b2b2b3;
    color: #8c8888;
    font-size: 14px;
    height: 22px;
    font-weight: 600;
    width: 100%;
    padding-left: 5px
}

.customLabel {
    font-size: 10px;
    color: #8a8a8a;
    margin-bottom: 0;
    font-weight: 400;
}

.customHeading {
    border-bottom: 4px solid #cccccc4d;
    font-weight: 700;
    font-size: 13px;
    color: #000;
    padding-bottom: 2px;
}

.customerTextarea3 {
    min-height: 60px;
    width: calc(100% - -7%);
    margin-left: -7%;
}

.customerTextarea {
    min-height: 60px;
    width: calc(100% - 3%);
    margin-left: 3%;
    font-weight: 500;
}

.customDescription {
    margin-left: 3%;
    width: calc(100% - 3%);
}

.custom_btn .dropdown button {
    margin-bottom: 15px;
}

.customInput-dropdown .dropdown button {
    padding: 3px 0;
    border: 0;
    height: 20px;
    font-size: 0.6rem !important;
    font-weight: 500;
    color: #8c8888 !important;
}

.customInput-dropdown .dropdown button:focus {
    box-shadow: none !important;
}

.customInput-dropdown {
    width: 100%;
}

.ws-nobreak {
    white-space: nowrap;
}

.productDescription.customerTextarea {
    min-height: 60px;
    width: calc(100% - 9.3%);
    margin-left: 9.3%;
}

td.border_left {
    border-left: 1px solid #d0d4d7;
    padding-left: 5px !important;
}

.margin_top {
    margin-top: 2rem;
}

.particular_img button.close {
    position: absolute;
    right: -8px;
    top: -7px;
    background-color: #ff0000;
    z-index: 999;
    padding: 0;
    font-size: 17px;
    opacity: 1;
    border-radius: 50%;
    height: 19px;
    width: 19px;
}

.particular_img {
    position: relative;
}

.particular_img button.close span {
    color: #fff;
    font-weight: 300;
    margin-top: -3px;
    display: inherit;
}

.view_img {
    background-color: #e1e1e1;
    width: 19px;
    line-height: 21px;
    height: 19px;
    border-radius: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: -7px;
    left: -7px;
}

button.dropdown-toggle::after {
    font-size: 15px;
    top: 0;
    right: 0;
}

.add_plus {
    display: flex;
    align-items: center;
    text-align: center;
}

.add_plus i {
    font-size: 25px;
    width: 100%;
}

.particular_img img {
    vertical-align: middle;
    border-style: none;
}

.addpart-image1 img {
    width: 50px;
}

.addpart-image1 {
    position: relative;
}

.addpart-image1 input#file {
    position: absolute;
    max-height: 50px;
    width: 50px;
}

.imgtype {
    text-align: center;
    border-radius: 50px;
    padding: 2px;
    background-color: #d23c45;
    color: #fff;
    font-size: 10px;
    margin-top: 7px;
}

.full-doc {
    height: 100%;
    width: 100%;
}

.user-name {
    display: inline-block;

}

.dropdown-user-link.link1 {
    display: flex !important;
    align-items: center;
}

.ws-nobreak .customLabel {
    white-space: nowrap;
}

.text-danger.input-error {
    font-size: 0.6rem !important;
}

button.add_img {
    background-color: #28d094;
    color: #fff;
}

input.customInput:focus {
    border: 1px solid #8c8888;
}

a.green-text.header_add {
    font-size: 10px;
}

.modal-header button.close {
    color: #fff;
    opacity: 1;
    font-weight: 300;
    line-height: 10px;
}

.modal .modal-content .modal-body {
    font-size: 12px;
    /* padding: 10px !important; */
}

.header_error,
#new-layout .header_error {
    position: absolute;
    top: 24px;
    left: 4rem;
}

.salestax_custom {
    max-height: 19px;
}

.status_error {
    left: 3rem;
    top: 27px;
}

button.btn.darkred-bg.round-btn {
    min-height: 23px;
    height: auto;
}

label.ortext {
    margin: 0;
    font-size: 10px;
    font-weight: 700;
}

span.ortext_order {
    font-size: 10px;
    margin-right: 15px;
}

tfoot.footOrder {
    border-top: 2px solid #bababa;
}

tfoot.footOrder tr:first-child td:first-child {
    border-right: 1px solid #bababa;
}

#DataTables_Table_0_paginate {
    margin-right: -5px !important;
}

button.close span {
    color: #fff;
    font-weight: 300;
}

button.close.btn.alert_modal {
    margin-top: -10px;
    opacity: 1;
}

h4.alert_heading {
    margin-right: -1px;
}

button.alert_modal:focus {
    box-shadow: none;
}

input#file.upload_icon_custom {
    max-width: 120px;
}

.f_size_12 {
    font-size: 12px;
}

.f_size_10 {
    font-size: 10px;
}

input.customInput.Cost {
    padding-left: 20px;
}

.part-cost-block .dollar {
    font-size: 11px;
    margin-left: -2px;
}

section#new-layout .order_header_error {
    position: absolute;
    top: 25px;
    left: 15px;
}

.error_abs {
    position: absolute;
}

.receive-vendor-error.ng-star-inserted {
    position: absolute;
    top: 22px;
}

section#new-layout .part-error {
    text-align: left;
    right: 0;
}

.purchase-part-error {
    top: 22px;
}

section#new-layout .shipping-error-customer {
    left: 6.2rem;
}

section#new-layout .shipping-error-status {
    left: 3rem;
}

section#new-layout .fetchedDetails span.text-danger {
    position: absolute;
    top: 50px;
}

.custom-vendor {
    margin-right: 10px;
    margin-left: -2px;
    width: 14px;
}

.custom-utility {
    width: 14px;
    margin-right: 9px;
    margin-left: 0;
}

.custom-partque {
    width: 14px;
    margin-left: 0;
    margin-right: 9px;
}

.custom-parts {
    width: 14px;
}

p.modal-para {
    font-size: 13px;
}

.modal-dialog {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100% !important;
}

.sweet-heading {
    height: 27px;
}

.thank-you-pop {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.thank-you-pop img {
    width: 76px;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-bottom: 12px;
}

.thank-you-pop h1 {
    font-size: 15px;
    margin-bottom: 15px;
    color: #5C5C5C;
}

.thank-you-pop p {
    font-size: 20px;
    margin-bottom: 27px;
    color: #5C5C5C;
}

.modal-footer.sweet-footer button {
    padding: 10px 20px;
    height: auto;
    font-size: 14px;
    border-radius: 5px;
}

.sweet-footer {
    border-top: none !important;
}

.purchasepage .table-responsive.actTable .form-control {
    max-height: 20px;
    font-size: 10px;
}

.modal-header.info-popup-header.view-img-modal button {
    margin-top: -16px;
}

label.label.sale_orderDetails {
    display: block;
}

.toast-message {
    color: #fff;
}

button.btn i.fas {
    color: #fff;
}

.modal-header button.close span:hover {
    color: #fff !important;
}

.content-section#new-layout label.customLabel.label-right {
    text-align: right !important;
}

.padding-r {
    padding-right: 36%;
    width: auto !important;
}

.customerTextarea2 {
    min-height: 60px;
    width: calc(100% - -7%);
    margin-left: -7%;
}

.customDescription2 {
    margin-left: -7%;
    width: calc(100% - -7%);
}

.customDescription-parts {
    margin-left: -7%;
    width: calc(100% - -7%);
}

body .dblock {
    display: block !important;
}

section.sidebar ul#main-menu-navigation li.nav-item {
    position: relative;
}



.color-setting1 {
    margin: 20px 0 10px;
}

#addpartModal1,
#addpartModalpopup,
#history,
#CheckCategoryModal,
#vendorModel,
#PartNameModel,
#SaveModel,
#AlreadyVendor,
#CustomPartitem,
#delete_success,
#deleteVendorlist,
#CalcPartModel,
#CalcPartSuccess,
#ExportOption,
#add_update,
#AddPartItem,
#AmountAlert,
#AmountAlert1,
#SelectPrinter,
#HelcimModal,
#PODiscountModal {
    background: rgb(0 0 0 / 32%);
}

.submit-from button {
    margin-top: 5px;
}

.text-over-ellipse {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

td.text-over-ellipse-td {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip-td {
    display: none;
    position: absolute;
    left: 0;
    width: 250px;
    white-space: pre-wrap;
    background: #000000b0;
    color: #fff;
    padding: 5px;
    z-index: 9;
    cursor: pointer;
    border-radius: 10px;
}

td.text-over-ellipse-td:hover .tooltip-td {
    display: block;
    left: 50px;
}

table.table-cursor a:hover,
i.fas.fa-search:hover {
    cursor: pointer;
}

.custom-width {
    min-width: 35px;
}

table.table-responsive {
    display: inline-table;
}

.pl-1px,
select.customInput {
    padding-left: 1px !important;
    font-weight: 500;
}

.pl-5px {
    padding-left: 5px !important;
}

.white-box1 .mt-3px {
    margin-top: -3px;
}

.validation_border {
    border: 1px solid red !important;
}

body .validation_border.borderNone {
    border: 0 !important;
}

.validation_border .dropdown {
    border: 1px solid red
}

.no-footer table.dataTable thead th:first-child {
    min-width: 50px;
}

h3.qty_hd {
    font-size: 13px;
    margin-bottom: 0;
}

.thank-you-pop h3.cupon-pop {
    font-size: 11px;
    margin-bottom: 20px;
    color: #222;
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    border: 2px dashed #222;
    clear: both;
    font-weight: normal;
}

span.discount.align-with input.p_relative {
    top: 4px;
}

.qus-action.align-with-box {
    top: -1px;
    right: -25px;
}

.VPName {
    width: 200px;
}

.setposition {
    position: absolute !important;
    left: 70px;
    top: -1px;
}

.light-color-text {
    color: #8a8a8a;
}

.arrowbutton {
    height: 18px;
    line-height: 12px;
    width: 20px;
    font-size: 1.0em;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
}


/* temaple style start*/
.company-widgets {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-right: 1px solid #ccc;
    height: auto;
    overflow-y: auto;
}

.company-widgets li {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    transition: 0.3s ease-out;
    font-size: 13px;
}

.widget-area {
    background-color: #fbfbfb;
}

.company-widgets li:hover {
    background-color: #fafafa;
}

.company-widgets li input {
    margin-right: 3px;
}

.producation-order {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
}

.count-value {
    font-size: 1.8rem !important;
}

.producation-order .order-height h5 {
    font-size: 16px;
    text-align: center;
}

.alert-notification {
    background: #fcedea;
    border: 1px solid #f8d6cf;
    padding: 11px 7px;
    border-radius: 20px;
    text-align: center;
    font-size: 16px !important;
    box-shadow: 10px 10px 10px 0px #f1d3d5
}

.alert-notification .fa-bell {
    border-radius: 6px;
    color: #ffffff;
    padding: 7px;
    margin-right: 10px;
    background: #d23c45;
}

.producation-order.button-training {
    background-image: linear-gradient(to right, #d23c45, #7c0209);
    margin-bottom: 8px;
    box-shadow: 5px 5px 5px 0px #b4a8a3;
}

.producation-order.button-training h6 {
    color: #fff;
}

.order-height {
    height: 40px;
}

.order-video {
    height: 180px;
    background: #76b2e5ad;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333;
    position: absolute;
    right: 0;
    min-width: 300px;
    max-width: 350px;
}

.news-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

.order-top-area {
    height: 50px;
    background-color: #ffffff;
    border-bottom: 1px solid #aaa;
    margin-right: -16px;
}

.fix-width-tooltip .tooltipWrap {
    width: 200px !important;
}

.fix-width-table .tooltipWrap1 {
    width: 100px !important;
}

table.table tr:nth-child(2) span.tooltips {
    top: -25px;
}

span.avatar {
    margin-left: 18px;
    overflow: unset;
    display: flex;
    height: 45px;
    width: 45px;
}

.search-section {
    align-items: center;
}

.mat-card {
    padding: 20px 13px 20px 20px !important;
}

.categoryColor {
    color: #000;
}

.LogContent {
    text-align: left
}

.table1-responsive1 {
    overflow-x: auto;
}

.autocomplete-container {
    height: 0px !important;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 17px !important;
}

.autocomplete-container .input-container input {
    height: 45px !important;
    border-left: 1px solid #a5a5a5 !important;
    font-size: 16px !important;
    border-radius: 0 !important;
}

.autocomplete-container .input-container .x i {
    color: #363434;
    font-size: 16px !important;
    vertical-align: middle;
    position: absolute;
    top: 16px;
    right: 0px;
}

.bolds b {
    font-size: 11px !important;
    color: #363434 !important;
    font-weight: 400;
}

.autocomplete-container .not-found {
    padding: 2px !important;
    border: 1px solid #f1f1f1;
    background: #fff;
    font-size: 13px !important;
    text-align: left;
}

.autocomplete-container .suggestions-container ul li a {

    font-size: 11px;
    text-align: left !important;
    padding: 4px 16px !important;
}

.multiselect-item-checkbox {
    position: relative;
    padding-left: 2em;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    margin: 0;
    font-size: 0.9rem !important;
    color: #8c8888 !important;
}

.fix-width-tooltip1 .tooltipWrapCost {
    width: 108px !important;
}

.tox-notification {
    background-color: #f8dede;
    border-color: #f2bfbf;
    color: #222f3e;
    display: none !important;
}

.mat-ank {
    font-size: 0.9rem !important;
    width: 100% !important;
}

.mat-ank .mat-form-field-infix {
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-weight: 500;
}

.mat-select-panel {
    max-height: 556px !important;
    margin-top: 21px !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #5C5C5C !important;
}

.mat-option-text,
.mat-ank .ng-star-inserted {
    color: #1A1C1E !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 12px !important;
}

.multiselect-dropdown .dropdown-down {
    border-top: 5px solid #adadad !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
    border-bottom: 5px solid #adadad !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn {
    padding: 2px !important;
}

.multiselect-dropdown .dropdown-list .item1 {
    display: none !important;
}

.multiselect-dropdown .ng-star-inserted {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #8a8a8a !important;
}

.multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]+div {
    padding-left: 3em !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    color: #fff !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* ----------------------------------------- */
.mat-body,
.mat-body-1,
.mat-typography {
    font: 400 14px/26px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
}

/* 22 - aug */
.cstm_inputtreeview_new {
    background: white;
    border-radius: 50px;
    line-height: 30px;
}

.cstm_inputtreeview_new .dropdown {
    height: unset !important;
    max-height: unset !important;
    border-radius: 50px;
    padding: 0px 8px 7px;
    border-color: #74777f;
}

.cstm_inputtreeview_new .dropdown button.dropdown-toggle:after {
    margin-top: 14px !important;
}

.cstm_childflex .mat-form-field-infix {
    display: flex;
}

.cstm_childflex .mat-form-field-infix input {
    font-size: 14px;
    width: 83%;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #e0e2ec;
}

::-webkit-scrollbar-thumb {
    background: #c3c1c1;
    border-radius: 5px;
}

.cstm_scrollbar_theme::-webkit-scrollbar-thumb:hover {
    background: #c3c1c1;
}

.example-container.cstm_scrollbar_theme table th.mat-header-cell {
    border-color: #43474e !important;
}

.card-desc.example-container.cstm_scrollbar_theme table {
    margin-top: 0;
}

.cstm-boxshadow-none .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    width: auto;
    font-size: 18px !important;
    color: #43474e;
    padding-right: 13px;
    background: #faf9fd;
}

.cstm-boxshadow-none .mat-form-field-appearance-outline .mat-form-field-label {
    font-size: 16px !important;
}

.cstm-boxshadow-none .mat-select-value-text {
    color: #191b1d;
    font-size: 16px;
}

.cstm-boxshadow-none .row:last-child .col-md-4 .mat-form-field-wrapper {
    padding-bottom: 0;
}

.cstmcard_shipings.mat-card .mat-card-header .mat-card-subtitle {
    margin-top: 0 !important;
}

.cstmcard_shipings.mat-card .mat-card-header .mat-card-title {
    margin-bottom: 0 !important;
}

.mat-form-field-wrapper {
    padding-bottom: 0;
}

.mat-select-arrow,
.purchaseOrder1 .mat-select-arrow {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none"><path d="M1.91 0L6.5 4.58L11.09 0L12.5 1.41L6.5 7.41L0.5 1.41L1.91 0Z" fill="%2343474E"/></svg>');
    height: 10px !important;
    width: 22px !important;
    border: none !important;
    background-repeat: no-repeat;
}

.down-chevron-date button.mat-focus-indicator.mat-icon-button.mat-button-base {
    color: transparent;
}

mat-icon.date-picker-down-chevron.mat-icon.notranslate.ng-tns-c4-1.material-icons.mat-icon-no-color {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none"><path d="M1.91 0L6.5 4.58L11.09 0L12.5 1.41L6.5 7.41L0.5 1.41L1.91 0Z" fill="%2343474E"/></svg>');
    ;
    height: 7px !important;
    width: 13px !important;
    border: none !important;
    background-repeat: no-repeat;
}

.mat-select-panel {
    background: #EEEDF1;
}

.mat-select-panel .mat-option {
    border-bottom: 1px solid #d3d3d3;
}

.cstmcard_shipings .mat-card-header-text {
    width: 100%;
}

.cdk-overlay-pane .mat-menu-panel {
    background: #eeedf1;
}

.cdk-overlay-pane .mat-menu-panel button.mat-menu-item {
    border-bottom: 1px solid #d4d5dc;
    font-size: 16px;
}

.cdk-overlay-pane .mat-menu-panel .mat-menu-content {
    padding-top: 0;
    padding-bottom: 0;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 99999 !important;
}

.swal2-styled.swal2-confirm {
    border-radius: 50px !important;
}

.swal2-styled.swal2-cancel {
    border-radius: 50px !important;
    background: #fff !important;
    color: #1e9ff2 !important;
    border: 1px solid #ddd !important;
}

.swal2-styled.swal2-confirm {
    min-width: 120px !important;
}

.sidebar-ank .mat-form-field-infix {
    padding: 0.5em 0;
    font-size: 14px;
    width: 135px;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: unset !important;
}

/* ----------------------------------------- */

/* ankit implementation */
/* --------------------------------------------- */
.utility-pageno .mat-paginator-page-size-label {
    font-size: 12px;
    margin: 0 15px 0 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.utility-pageno .mat-form-field-infix {
    padding: 1em 0;
    font-size: 14px;
    width: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.utility-pageno .next-prev {
    margin: 0 0 0 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.utility-pageno .pageno {
    font-size: 12px;
    margin: 0 5px 0 5px;
    padding: 15px 18px;
    border: 1px solid #bbbbbb;
    background-color: #e7e7e7;
    border-radius: 50%;
}

.common-button-upload {
    line-height: 2.0;
    background-color: #005FAE;
    font-weight: 500;
    width: 280px;
    font-size: 15px;
    color: #fff;
}

.packaging-mat-header-text .mat-card-header-text {
    margin: 0 !important;
}

.swal2-header {
    align-items: start !important;
    margin-bottom: 16px;

}

.swal2-popup {
    width: 350px !important;
    padding: 24px !important;
    background-color: #E9E8EB !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #74777F !important;
}

.swal2-content {
    text-align: start !important;
}

.swal2-actions button {
    font-size: 14px !important;
}

.swal2-cancel.swal2-styled {
    color: rgb(48, 133, 214) !important;
}

.mat-grid-tile .mat-figure {
    display: block !important;
    height: auto;
    margin: auto;
}

.mat-card {
    height: 100%;
}

.ipad-view-table {
    padding: 10px;
}

/* ---------company css start here-------- */
.search_filter-alltype .mat-mdc-select-arrow svg {
    left: 100% !important;
}

.additional-section .mat-mdc-select span {
    font-size: 14px;
    color: black;
}

.additional-section .mdc-line-ripple {
    border-bottom: none !important;
    display: none;
}

.additional-section .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
    background-color: transparent !important;
}

.company-card .mat-mdc-card-content {
    padding: 15px !important;
}

.additional-section .mat-mdc-form-field-infix {
    width: auto !important;
}

.additional-section .mat-mdc-text-field-wrapper {
    padding: 0px 5px !important;
}

/* ---------company css end here-------- */

/* ---------picklist css starthere-------- */
.btnManuallySelect span.mat-mdc-menu-item-text {
    font-weight: 500;
}

/* ---------picklist css end here-------- */

/*   UI size increase for large screen resolution   */

.mat-option-value {
    padding: 10px !important;
    border-bottom: 1px solid silver !important;
}

.image-slider-main .ng-image-slider,
.image-slider-main .main {
    height: 100% !important;
}

.image-slider-main a.next.icons.next-icon,
.image-slider-main a.prev.icons.prev-icon {
    color: #005FAE;
    font-size: 55px !important;
}

.image-slider-main a.next.icons.next-icon:hover,
.image-slider-main a.prev.icons.prev-icon:hover {
    background-color: none !important;
    color: #005FAE;
}

.modal-footer.sweet-footer {
    padding-right: 44px;
}

.image-slider-main .main .main-inner .img-div img.image {
    background: #fff;
    padding: 10px;
}

.carousel-control-prev {
    left: -52px !important;
}

.carousel-control-next {
    right: -52px !important;
}

.mat-content {
    padding: 10px 15px !important;
}

.carousel-control-prev-icon {
    background-image: url("../src/assets/image/left_vector.svg") !important;
    height: 31px !important;
    opacity: 1 !important;
}

.carousel-control-next-icon {
    background-image: url("../src/assets/image/right_vector.svg") !important;
    height: 31px !important;
    opacity: 1 !important;
}

.carousel-control-next,
.carousel-control-prev {
    opacity: 2 !important;
}

.swal2-content {
    padding: 0px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.swal2-header {
    padding: 0 !important;
}

.swal2-popup.swal2-modal.swal2-show {
    width: 312px !important;
}

button.swal2-cancel.swal2-styled {
    background-color: #005fae !important;
    color: white !important;
    padding: 10px 36px;
}

h2#swal2-title {
    color: #1A1C1E !important;
    font-size: 24px;
    font-weight: 400;
}

.vendor-cards .mat-mdc-text-field-wrapper,
.shipto-card .mat-mdc-text-field-wrapper {
    max-height: 40px;
    height: 100%;
}

.vendor-cards .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.shipto-card .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 11px;
}

.icon-change-input svg {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none"><path d="M1.91 0L6.5 4.58L11.09 0L12.5 1.41L6.5 7.41L0.5 1.41L1.91 0Z" fill="%2343474E"/></svg>');
    height: 9px !important;
    width: 13px !important;
    border: none !important;
    background-repeat: no-repeat;
    color: transparent;
}

.mat-mdc-cell {
    border-bottom: 1px solid var(--border-table) !important;
}

.accordian-section .mat-expansion-toggle-indicator-before .mat-content.mat-content-hide-toggle {
    margin-left: 10px !important;
    margin-right: 0;
}

.search_filter-alltype span.mat-mdc-select-min-line {
    padding: 10px;
}

/* -----------material input change css-------------- */
.form-fields-custom .height-change .mat-mdc-form-field-infix {
    padding: 5px;
    min-height: 20px;
    align-items: center;

}

.form-fields-custom .mat-mdc-text-field-wrapper {
    padding: 0px 5px;
}

.form-fields-custom .height-change .mat-icon {
    height: 18px;
    width: 24px;
    padding: 8px;
}

.mat-card-custom .change-mat-header .mat-mdc-card-header-text {
    display: none;
}

.form-fields-custom .height-change .mdc-notched-outline__leading,
.form-fields-custom .height-change .mdc-notched-outline__trailing {
    border: 0px;
}

.form-fields-custom .mat-mdc-form-field-infix {
    width: auto !important;
}

.select-add-field .mat-mdc-form-field-infix {
    width: 180px !important;
}

.form-fields-custom .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-right: 0;
    padding-left: 0px;
}

.add-item-btn span {
    font-weight: 500;
}

.change-modal-bg {
    background-color: #E9E8EB !important;
    border-radius: 10px !important;
}

.change-modal-bg .mat-mdc-form-field {
    background-color: #F1F0F4;
}

.modal {
    background-color: #000000a6;
}

.accordian-section mat-form-field {
    width: 100%;
}

.example-full-width .mat-mdc-text-field-wrapper {
    height: 56px;
}

.table-order-part .change-width mat-form-field .mat-mdc-text-field-wrapper {
    height: 45px;
    margin: 5px 0px !important;
}

.table-material thead {
    background: #e3e2e6 !important;
}

.change-height-field .mat-mdc-text-field-wrapper {
    height: 32px;
}

.change-height-field .mat-mdc-form-field-infix {
    padding: 0px !important;
}

.change-height-field .mat-mdc-form-field-infix input {
    height: 32px !important;
}

mat-form-field {
    width: 100%;
}

.forShipping.status-select {
    width: auto;
}

.cdk-overlay-pane {
    width: auto !important;
}


.mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: #E9E8EB;
}

.top-bredcumps {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    background: #e3e2e6;
    align-items: center;
    height: 61px;
    padding-left: 20px;
    margin-bottom: 1rem;
}

.content-section-2 .top-bredcumps-second {
    margin-left: 280px;
}

.content-section-2 .top-bredcumps {
    margin-left: 280px;
}

.content-section-2 .top-bredcumps-second .top-bredcumps {
    margin-left: 0;
}

.mat-expansion-panel-spacing {
    margin-top: 0px !important;
}

mat-option span {
    display: block;
}

span:empty {
    display: none;
    margin: 0px !important;
}

.company-table .bg-header thead {
    background-color: #EEEDF1;
}

.company-table table th {
    color: #43474E;
    font-size: 16px;
}

.mat-header-bg {
    background-color: #EEEDF1;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.mat-mdc-select span {
    color: #1A1C1E;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgb(0 0 0 / 41%) !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    color: rgb(0 0 0) !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined.mdc-text-field--disabled {
    background-color: #f4f3f7 !important;
}

mat-label {
    color: #43474E;
}

.table-scroll {
    max-height: calc(100vh - 460px);
    overflow: auto;
}

.part-table-scroll {
    max-height: calc(100vh - 225px);
    overflow: auto;
}

.table-scroll-2 {
    max-height: 80vh;
}

.cstm-radiosearch label {
    cursor: pointer;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    height: auto !important;
}

.quantity-div mat-form-field .mat-mdc-form-field-infix {
    align-items: center;
}

.unit-cost-section mat-form-field .mat-mdc-form-field-infix {
    align-items: center;
}

.quantity-div mat-form-field .mat-mdc-form-field-infix input {
    text-align: center;
}

.unit-cost-section mat-form-field .mat-mdc-form-field-infix input {
    text-align: right;
}

mat-icon {
    cursor: pointer;
}

.mat-sort-header-content {
    color: #43474E;
    font-weight: 500;
    font-size: 14px;
}

#LocationModal .modal-dialog {
    max-width: 600px;
}

.fullfillment-table mat-row {
    cursor: pointer;
}

/*Start modal style */

.modal .modal-dialog .modal-content {
    background-color: #E9E8EB;
    border-radius: 10px;
}

.modal .modal-dialog .modal-content .modal-header {
    margin: 2px 6px;
}

.modal .modal-dialog .modal-content .modal-body {
    margin: 2px 6px;
}

.modal-body.shipping-rate-modal {
    max-height: 525px;
    overflow: auto;
}

.modal .modal-dialog .modal-content .modal-body .mdc-text-field--outlined {
    border-color: var(--border-dark);
    background-color: #F1F0F4;
    color: #1A1C1E;
}

button.cancel-button {
    border-color: #74777F;
    padding: 5px 24px;
}

.modal .modal-dialog .modal-content .modal-footer .cancel-button {
    border-color: #74777F;
    padding: 5px 24px;
}

.modal .modal-dialog .modal-content .modal-footer .saveclosebnt {
    padding: 5px 24px;
}

.modal .disc-list li {
    list-style: disc;
}

/* button{
    cursor: pointer;
} */
.modal .modal-dialog .modal-content h2 {
    color: #000;
    font-size: 22px;
    font-weight: 400;
}

.modal .modal-dialog .modal-content h2+span,
.modal .modal-dialog .modal-content {
    color: #43474E;
    font-size: 14px;
    font-weight: 400;
    margin: 15px 0 0 0;
}

.modal .modal-dialog .modal-content .modal-footer .row {
    padding-bottom: 12px;
}

#BillToAddressModal .modal-dialog {
    max-width: 630px;
}

#ShipToAddressModal .modal-dialog {
    max-width: 630px;
}

.modal-footer {
    gap: 10px;
}

/*End modal style */
/* ------------------------putaway custom css start ------------------ */
.custom-field mat-form-field .mat-mdc-form-field-infix {
    padding: 0px !important;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.edit-section .tox-toolbar__primary,
.edit-section .tox .tox-edit-area__iframe,
.edit-section .tox .tox-statusbar,
.edit-section .tox-editor-header {
    background-color: #e9e8eb !important;
}

.edit-section .tox-tinymce {
    border: 1px solid var(--border-dark);
}

.mat-mdc-dialog-content {
    max-height: 100% !important;
}

.mdc-text-field__input {
    font-size: 16px !important;
}

.mdc-linear-progress__buffer-bar {
    background-color: #e3e2e6 !important;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
    height: auto;
}

/* ------------------------------------------------------------------------------------------------media query start----------------------------------------- */
@media (max-width:1920px) {
    .table-responsive {
        max-height: 68vh;
    }
}

@media screen and (min-width: 1600px) {
    .qus {
        font-size: 0.8rem;
    }

    .mat-h5,
    .mat-typography h5 {
        font-size: 1rem;
    }

    .modal-body .form-control {
        font-size: 0.8rem !important;
    }

    .OrderPartsDetails tr.hoverEffect span,
    tr.hoverEffect.OrderPartsDetails td,
    tr.hoverEffect.OrderPartsDetails span,
    tr.hoverEffect.OrderPartsDetails td span.onEditCurrText {
        font-size: 0.8rem !important;
    }

    .card-icon {
        height: 80px;
        width: 80px;
        line-height: 80px;
        font-size: 2.1rem;
    }

    .card-desc img {
        width: 80%;
    }

    .dataTables_length,
    .dataTables_filter {
        font-size: .9rem !important;
    }

    .dataTables_wrapper label,
    .dataTables_wrapper input,
    .dataTables_wrapper select {
        font-size: .9rem !important;
        height: 30px !important;
    }


    td img {
        max-height: 50px;
        width: 50px;
        height: 50px;
    }

    a.green-text.header_add {
        font-size: .9rem;
    }

    .dataTables_info {
        font-size: 0.9rem !important;
    }

    div#DataTables_Table_0_paginate {
        font-size: .8rem !important;
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        font-size: .8rem !important;
    }

    .btn.sq-btn {
        height: 20px;
        font-size: .9rem;
    }

    .lg-text {
        font-size: 2.9rem;
    }

    body {
        font-size: 1.2rem !important;
    }

    .customHeading {
        font-size: 18px;
    }

    .customInput-dropdown .dropdown button {
        font-size: 0.9rem !important;
    }

    .header-left {
        width: 280px;
    }

    .header-right .dropdown-user a {
        font-size: 18px;
    }

    .user-name span.fs-11 {
        font-size: 14px;
        text-transform: capitalize;
        line-height: 25px;
    }

    .sidebar .navigation li a {
        font-size: 16px;
    }

    .bread-crumb a,
    .bread-crumb span {
        font-size: 0.9rem;
    }

    .customInput,
    .customInput-dropdown .dropdown,
    .customInput-dropdown .dropdown .row-filter .form-control {
        font-size: 0.9rem !important;
        height: 27px !important;
    }

    .content-section-1 {
        margin-left: 280px;
        /* margin-bottom: 50px; */
    }

    .fetchedDetails ul li.d-flex label {
        font-size: 0.9rem;
    }

    input[type=checkbox],
    input[type=radio] {
        height: 18px;
        width: 18px;
    }

    .btn.round-btn {
        height: 29px;
        padding: 5px 12px;
        font-size: 0.9rem;
    }

    .table-responsive table tr td {
        font-size: 0.9rem;
    }

    b {
        font-size: 0.9rem;
    }

    .hrdLogoSize {
        height: 46px;
        width: auto;
    }

    .header-right i {
        font-size: 1.7rem;
    }

    /* .footer {
        font-size: .9rem;
    } */

    .d-width {
        padding-right: 15px;
    }

    .autocomplete-container .input-container input {
        width: 600px !important;
    }

    i.fa.fa-info-circle.fabnew {
        position: absolute;
        right: 0px;
        top: -15px;
    }

    .cstmbreadcum.paddingmng-right {
        top: 60px;
    }
}

@media (max-width:1599px) {
    .top-bredcumps {
        /* top: 60px !important; */
        height: 61px;
    }

    .top-bredcumps-second {
        /* top: 60px; */
    }

    .menu-sidebar-top {
        top: 60px;
    }

    .sidebar {
        top: 122px;
    }
}

@media (min-width:1200px) and (max-width:1600px) {
    .column-width {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .column-width-full {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 1366px) {

    .sidebar ul li .sub-menu a {
        padding-left: 0;
    }

    .sub-menu.active {
        display: block;
        padding-left: 50px;
    }

    .company-table table thead {
        display: none !important;
    }

    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 500;
        margin-right: 40px;
        width: 100px;
    }

    .remove-label td::before {
        content: "";
        /* This will ensure there's no content */
    }

    .company-table table tbody tr {
        background: #faf9fd !important;
        border-bottom: 1px solid silver;
        padding: 8px 0;
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }

    .company-table table tr td {
        max-width: 50% !important;
        width: 50% !important;
        border: 0px !important;
    }
}

@media (max-width:1024px) {
    .content-section-2 .top-bredcumps-second {
        margin-left: 0;
    }

    .menu_collapsed .menu-sidebar-top {
        left: -280px !important;
        transition: 0.5s !important;
    }

    .menu_collapsed .top-bredcumps {
        margin: 0px;
    }

    .content-section-2 .top-bredcumps {
        margin-left: 0;
    }

    .add-btn-res {
        padding: 2px 15px;
    }

    .content-section-1 {
        margin-left: 139px;
        transition: 0.5s;
    }

    .mat-card {
        padding: 20px 8px 20px 20px !important;
    }

    /* .menu-collapsed .packaging-drop-icon {
        width: 4% ;
      } */
    .menu_collapsed .packaging-drop-icon {
        width: 6%;
    }

    .autocomplete-container .input-container input {
        width: 234px !important;
    }

    .custom {
        width: 150px;
        position: absolute;
        top: -42px;
    }

    .content-section-1 {
        margin-left: 0px;
        transition: 0.5s;
    }

    .menu_collapsed.content-section-1 {
        margin-left: 0px;
        transition: 0.5s;
    }

    .custom1 {
        width: 150px;
    }

    .for-mobile {
        display: none;
    }

    .input-container input {
        display: none !important;
    }

    .input-group-prepend select {
        display: none;
    }

    .quick-ord-btn {
        padding: 10px 10px;
    }

    .header-right .nav-item {
        padding: 0px;
    }

    .header-right .dropdown-user a {
        padding: 0;
    }

    span.avatar {
        margin-left: 0;
        overflow: unset;
        display: flex;
        height: 45px;
        width: 45px;
    }

    .search-section {
        align-items: center;
        z-index: 9999;
    }

    /* section.footer {
        position: absolute;
        bottom: 0;
        left: 0%;
    } */

    /* ---------------------------------------inventory table css----------------------------- */
    .material-inventory-row {
        padding: 5px;
        border: 1px solid silver;
    }

    .inventory-header {
        display: none;
    }

    .inventory-table .inventory-mat-cell:before,
    .inventory-table-new td::before {
        content: attr(data-label);
        float: left;
        font-weight: 500;
        margin-right: 40px;
        max-width: 130px;
        width: 100%;
        padding: 4px 0px;
        color: #000;
    }

    .inventory-table-new thead {
        display: none;
    }

    .inventory-table-new tr {
        height: 208px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid silver;
        align-items: center;
    }

    .inventory-table-new td {
        border: 0px !important;
        width: 50%;
    }

    .inventory-table-new tfoot tr {
        height: 100% !important;
    }

    .inventory-table-new tfoot tr td {
        display: flex;
        align-items: center;
    }

    .inventory-table-new tfoot tr td:empty {
        display: none;
    }

    .inventory-table mat-cell,
    mat-header-cell,
    mat-footer-cell {
        min-height: unset;
    }

    .inventory-table-new table tr td:nth-child(7) {
        width: 100% !important;
    }

    .select-add-field .mat-mdc-form-field-infix {
        width: 100px !important;
    }

    /* ---------------------------------------inventory table css end----------------------------- */
}

@media only screen and (max-width: 991px) {

    /* .footer {
        margin-left: 0;
        background: #fff;
        padding: 0 15px;
        bottom: 0;
        position: inherit;
        right: 0;
        font-size: 10px;
    } */

    .mat-card {
        padding: 20px 8px 20px 20px !important;
    }

    .upload-utility {
        text-align: right;
    }

    .customDescription-parts {
        width: calc(100% - -13%);
        margin-left: -13%;
    }

    .customerTextarea3 {
        min-height: 60px;
        width: calc(100% - -13%);
        margin-left: -13%;
    }

    .customerTextarea {
        min-height: 60px;
        width: calc(100% - 0%);
        margin-left: 0;
    }

    .customDescription {
        margin-left: 0%;
        width: calc(100% - 0%);
    }

    button.upload_btn {
        text-align: right;
        float: right;
    }

    .purchasepage .vendor_res {
        display: block !important;
    }

    textarea.customInput.productDescription1.customerTextarea {
        min-height: 60px;
        width: calc(100% - 3%);
        margin-left: 3%;
    }

    section.content-section .trp {
        text-align: left;
        padding-right: 15px;
    }

    .customLabel {
        white-space: nowrap;
    }

    .header_error,
    #new-layout .header_error {
        position: absolute;
        top: 6px;
        right: 3rem;
        left: auto;
        margin-bottom: 30px;
        z-index: 9;
    }

}


@media only screen and (max-width:767px) {
    i.fa.fa-info-circle.fabnew {
        position: absolute;
        right: 0px;
        top: -11px;
    }

    .margin-phone {
        margin: 5px 0;
    }


    .customDescription-parts {
        margin-left: 0;
        width: calc(100% - 0%);
    }

    .customerTextarea3 {
        min-height: 60px;
        width: calc(100% - 0%);
        margin-left: 0%;
    }

    .modal-dialog {
        margin: 0;
    }

    .modal-content {
        width: 95% !important;
    }

    .loading:not(:required) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .productDescription.customerTextarea {
        min-height: 60px;
        width: calc(100% - 0%);
        margin-left: 0;
    }

    textarea.customInput.productDescription1.customerTextarea {
        min-height: 60px;
        width: calc(100% - 0%);
        margin-left: 0%;
    }

    .modal-content .col-md-1.col-1.text-left.or_width i {
        padding-top: 7px;
        margin-left: 0 !important;
    }

    ul.navbar-nav li.nav-item.hidden-xs1 {
        visibility: hidden;
    }

    ul.navbar-nav li.nav-item.hidden-sm {
        padding-top: 10px;
    }

    .form-fields-custom .height-change .mat-mdc-form-field-infix {
        width: auto;
    }

    .top-bredcumps .link_current li:last-child {
        position: absolute !important;
        top: 4px;
        left: 50%;
        transform: translate(-50%, 0px);
    }

    .top-bredcumps {
        height: auto;
        padding: 35px 5px 10px 5px;
    }

    .top-bredcumps .link_current li:last-child::before {
        content: '' !important;
    }

    .top-bredcumps .link_current li:last-child span {
        color: #000;
    }

    .sidebar {
        top: 140px;
    }

}

@media (max-width:420px) {
    .page_title_cstms a.backbtns span {
        display: none;
    }
    .page_title_cstms a::after {
        content: 'Back';
        color: #005FAE;
        font-weight: 500;
    }
}



/* ----------------------------------------- */

/* ankit implementation */
.mat-ank {
    font-size: 0.9rem !important;
    width: 100% !important;
}

.mat-ank .mat-form-field-infix {
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-weight: 500;
}

.mat-select-panel {
    max-height: 556px !important;
    margin-top: 21px !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #5C5C5C !important;
}

.mat-option-text,
.mat-ank .ng-star-inserted {
    color: #1A1C1E !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 12px !important;
}

.multiselect-dropdown .dropdown-down {
    border-top: 5px solid #adadad !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
    border-bottom: 5px solid #adadad !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn {
    padding: 2px !important;
}

.multiselect-dropdown .dropdown-list .item1 {
    display: none !important;
}

.multiselect-dropdown .ng-star-inserted {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #8a8a8a !important;
}

.multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]+div {
    padding-left: 3em !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    color: #fff !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* ----------------------------------------- */

.mat-body,
.mat-body-1,
.mat-typography {
    font: 400 14px/26px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
}

/* 22 - aug */
.cstm_inputtreeview_new {
    background: white;
    border-radius: 50px;
    line-height: 30px;
}

.cstm_inputtreeview_new .dropdown {
    height: unset !important;
    max-height: unset !important;
    border-radius: 50px;
    padding: 0px 8px 7px;
    border-color: #74777f;
}

.cstm_inputtreeview_new .dropdown button.dropdown-toggle:after {
    margin-top: 14px !important;
}

.cstm_childflex .mat-form-field-infix {
    display: flex;
}

.cstm_childflex .mat-form-field-infix .mat-select-value {
    padding-right: 5px;
}

.cstm_childflex .mat-form-field-infix input {
    font-size: 14px;
    width: 83%;
}

::-webkit-scrollbar-thumb {
    background: #c3c1c1;
    border-radius: 5px;
}

.example-container.cstm_scrollbar_theme table th.mat-header-cell {
    border-color: #43474e !important;
}

.card-desc.example-container.cstm_scrollbar_theme table {
    margin-top: 0;
    cursor: pointer;
}

.cstm-boxshadow-none .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    width: auto;
    font-size: 18px !important;
    color: #43474e;
    padding-right: 13px;
    background: #faf9fd;
}

.cstm-boxshadow-none .mat-form-field-appearance-outline .mat-form-field-label {
    font-size: 16px !important;
}

.cstm-boxshadow-none .mat-select-value-text {
    color: #191b1d;
    font-size: 16px;
}

.cstm-boxshadow-none .row:last-child .col-md-4 .mat-form-field-wrapper {
    padding-bottom: 0;
}

.cstmcard_shipings.mat-card .mat-card-header .mat-card-subtitle {
    margin-top: 0 !important;
}

.cstmcard_shipings.mat-card .mat-card-header .mat-card-title {
    margin-bottom: 0 !important;
}

.mat-form-field-wrapper {
    padding-bottom: 0;
}

.select-arrow-change-icon .mat-mdc-select-arrow,
.change-icon-datepicker .mat-icon {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none"><path d="M1.91 0L6.5 4.58L11.09 0L12.5 1.41L6.5 7.41L0.5 1.41L1.91 0Z" fill="%2343474E"/></svg>');
    height: 9px !important;
    width: 13px !important;
    border: none !important;
    background-repeat: no-repeat;
    color: transparent;
}

.select-arrow-change-icon path {
    display: none;
}

#printPackingSlip .select-arrow-change-icon .mat-mdc-select-arrow-wrapper {
    height: auto !important;
}

.po-test-flyout path {
    display: block;
}

.change-icon-datepicker .mat-mdc-icon-button {
    top: 40%;
}

.mat-select-panel {
    background: #EEEDF1;
}

.mat-select-panel .mat-option {
    border-bottom: 1px solid #d3d3d3;
}

.cstmcard_shipings .mat-card-header-text {
    width: 100%;
}

.cdk-overlay-pane .mat-menu-panel {
    background: #eeedf1;
}

.cdk-overlay-pane .mat-menu-panel button.mat-menu-item {
    border-bottom: 1px solid #d4d5dc;
    font-size: 16px;
}

.cdk-overlay-pane .mat-menu-panel .mat-menu-content {
    padding-top: 0;
    padding-bottom: 0;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1050 !important;
}

.swal2-styled.swal2-confirm {
    border-radius: 50px !important;
}

.swal2-styled.swal2-cancel {
    border-radius: 50px !important;
    background: #fff !important;
    color: #1e9ff2 !important;
    border: 1px solid #ddd !important;
}

.swal2-styled.swal2-confirm {
    min-width: 120px !important;
}

.sidebar-ank .mat-form-field-infix {
    padding: 0.5em 0;
    font-size: 14px;
    width: 135px;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: unset !important;
}

.totai-id .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 100% !important;
}

.totai-id .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 10px 0px !important;
}

@media (max-width:1024px) {
    .desktop-table {
        display: none;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 15px !important;
    }

    .ipad-view-table {
        display: block;
    }

    .company-table table thead tr th:nth-of-type(8),
    .company-table table thead tr th:nth-of-type(9) {
        display: none !important;
    }

    .company-table table tbody tr td:nth-of-type(8),
    .company-table table tbody tr td:nth-of-type(9),
    .company-table table thead tr td:nth-of-type(6),
    .company-table table thead tr td:nth-of-type(7),
    .company-table table thead tr td:nth-of-type(8),
    .company-table table thead tr td:nth-of-type(9) {
        display: none !important;
    }

    .company-table table tbody tr td:nth-of-type(7) {
        position: relative;
        max-width: 113px !important;
        padding-right: 25px;
        text-overflow: ellipsis;
    }

    .company-table table tbody tr td:nth-of-type(5) {
        /* display: block; */
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .company-table table thead tr th:nth-of-type(5) {
        width: 170px;
    }

    .company-table table tbody tr td:nth-of-type(4) {
        width: 120px;
    }

    .company-table table thead tr th:nth-of-type(4) {
        width: 120px;
    }

    .company-table table tbody tr td:nth-of-type(7)::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 14px;
        background-image: url(../src/assets/image/vector-right-icon.png);
        height: 12px;
        width: 7.41px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .menu_collapsed .content-section-1 {
        margin-left: 0px;
        transition: 0.5s;
    }

    .list-view-table table thead tr th:nth-of-type(1),
    .list-view-table table thead tr th:nth-of-type(3),
    .list-view-table table thead tr th:nth-of-type(4),
    .list-view-table table thead tr th:nth-of-type(8),
    .list-view-table table thead tr th:nth-of-type(9),
    .list-view-table table thead tr th:nth-of-type(12) {
        display: none;
    }

    .list-view-table table tbody tr td:nth-of-type(1),
    .list-view-table table tbody tr td:nth-of-type(3),
    .list-view-table table tbody tr td:nth-of-type(4),
    .list-view-table table tbody tr td:nth-of-type(8),
    .list-view-table table tbody tr td:nth-of-type(9),
    .list-view-table table tbody tr td:nth-of-type(12) {
        display: none;
    }

    .shiped .mat-form-field-label-wrapper,
    .package .mat-form-field-label-wrapper,
    ul.value-section.added-row .shiped .mat-form-field-label-wrapper,
    ul.value-section.added-row .package .mat-form-field-label-wrapper {
        height: 100%;
        overflow: hidden;
        padding: inherit;
        background: none;
        top: -0.84375em;
        left: 0;
    }

    .hrdLogoSize {
        height: auto;
        width: 123px;
        object-fit: cover;
    }

    table tbody tr td:last-child {
        display: none;
    }


    .sales-discount-table tbody tr td:last-child {
        display: block;
    }


    .picklist-table tbody tr td:last-child {
        display: block !important;
    }

    /* -------------------fulfillment table responsive------------ */
    .table-responsive .table-responsive-header {
        display: none !important;
    }

    .table-responsive mat-row {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid silver !important;
    }

    .table-responsive mat-row:nth-child(2n+0) {
        border-right: 1px solid silver !important;
    }

    .table-responsive mat-row mat-cell {
        border-bottom: 0px !important;
        min-height: 26px;
        display: inline-block;
        width: 50%;
    }

    .table-responsive mat-row mat-cell:before {
        content: attr(data-label);
        display: inline-block;
        color: #000;
        font-weight: 500;
        width: 160px;
    }

    /* ------------------purchase order list table ---------------------*/
    .table-material thead {
        display: none !important;
    }

    .table-material tbody tr {
        display: flex;
        flex-wrap: wrap;
        height: auto !important;
        border-bottom: 1px solid silver;
    }

    .table-material tbody tr td {
        width: 50%;
        border-bottom: 0px !important;
        line-height: 35px;
    }

    .table-material tbody tr td:before {
        content: attr(data-label);
        width: 150px;
        display: inline-block;
        color: #000;
        font-weight: 500;
    }

    /* -------------------fulfillment table responsive------------ */
}

/* --------------------------------------------------- */
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {

    .toggle-btn {
        width: 45%;
    }

    .logo-nav {
        float: right;
    }

    .company-table table thead tr th:nth-of-type(8),
    .company-table table thead tr th:nth-of-type(9) {
        display: none !important;
    }

    .company-table table tbody tr td:nth-of-type(8),
    .company-table table tbody tr td:nth-of-type(9),
    .company-table table thead tr td:nth-of-type(6),
    .company-table table thead tr td:nth-of-type(7),
    .company-table table thead tr td:nth-of-type(8),
    .company-table table thead tr td:nth-of-type(9) {
        display: none !important;
    }

    .company-table table tbody tr td:nth-of-type(7) {
        position: relative;
    }

    .company-table table tbody tr td:nth-of-type(5) {
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .company-table table thead tr th:nth-of-type(5) {
        width: 170px;
    }

    .company-table table tbody tr td:nth-of-type(4) {
        width: 120px;
    }

    .company-table table thead tr th:nth-of-type(4) {
        width: 120px;
    }

    .company-table table tbody tr td:nth-of-type(7)::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 14px;
        background-image: url(../src/assets/image/vector-right-icon.png);
        height: 12px;
        width: 7.41px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .list-view-table table thead tr th:nth-of-type(1),
    .list-view-table table thead tr th:nth-of-type(3),
    .list-view-table table thead tr th:nth-of-type(4),
    .list-view-table table thead tr th:nth-of-type(8),
    .list-view-table table thead tr th:nth-of-type(9),
    .list-view-table table thead tr th:nth-of-type(12) {
        display: none;
    }

    .list-view-table table tbody tr td:nth-of-type(1),
    .list-view-table table tbody tr td:nth-of-type(3),
    .list-view-table table tbody tr td:nth-of-type(4),
    .list-view-table table tbody tr td:nth-of-type(8),
    .list-view-table table tbody tr td:nth-of-type(9),
    .list-view-table table tbody tr td:nth-of-type(12) {
        display: none;
    }

    .shiped .mat-form-field-label-wrapper,
    .package .mat-form-field-label-wrapper,
    ul.value-section.added-row .shiped .mat-form-field-label-wrapper,
    ul.value-section.added-row .package .mat-form-field-label-wrapper {
        height: 100%;
        overflow: hidden;
        padding: inherit;
        background: none;
        top: -0.84375em;
        left: 0;
    }

    .sales-discount-table {
        border: 1px solid var(--border);
    }

}


/*  */
@media screen and (max-width: 600px) {
    .accordian-section .mat-expansion-toggle-indicator-before .mat-content.mat-content-hide-toggle {
        margin-left: 0px !important;
        margin-right: 0;
        padding: 6px !important;
    }

    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    .second-card .form-fields-custom {
        min-width: auto !important;
    }

    .ready-to-pic_table table thead {
        position: relative;
    }

    .ready-to-pic_table table thead tr:nth-child(1) {
        height: 1px;
    }

    .ready-to-pic_table table thead tr th:nth-child(1) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(2) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(3) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(4) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(5) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(6) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(7) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(8) {
        display: none;
    }

    .ready-to-pic_table table thead tr th:nth-child(9) {
        display: none;
    }

    table tr {
        border-bottom: 2px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .sales-discount-table tr {
        display: table-row;
        border: 0px;
    }

    table td {
        display: block;
    }

    .sales-discount-table tr td {
        display: table-cell;
    }

    .sales-discount-table {
        border: 1px solid var(--border);
    }

    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 500;
        margin-right: 40px;
        width: 100px;
    }

    .picklist-table td::before {
        width: 150px !important;
    }

    .company-table table tbody tr td:nth-of-type(1) {
        display: none;
    }

    tr.mat-row,
    tr.mat-footer-row {
        height: auto !important;
    }

    .company-table table tbody tr td:nth-of-type(5) {
        display: block;
        width: 400px;
    }

    .purchaseOrder table tr td:nth-child(3) {
        width: 100%;
        max-width: 100% !important;
        display: block;
        position: relative;
    }

    .company-table table tbody tr td:nth-of-type(4) {
        width: 100% !important;
        display: block;
    }

    .company-table table tbody tr td:nth-of-type(5) {
        display: block;
        width: 81%;

    }

    .company-table table tbody tr td:nth-of-type(6) {
        display: block;
        width: 100% !important;
        position: relative;
    }

    .company-table table tbody tr td:nth-of-type(7) {
        display: none;
    }

    .company-table table tbody tr td {
        border: 0 !important;
    }

    .company-table table tbody tr {
        border-bottom: 1px solid silver;
        padding: 8px 0;
    }

    .purchaseOrder table tr td:nth-child(3):after {
        content: '';
        position: absolute;
        top: 0;
        right: 40px;
        background-image: url(../src/assets/image/more.png);
        height: 23px;
        width: 27.41px;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.6;

    }

    tr:nth-child(even) {
        background: #FFF;
    }

    tr:nth-child(odd) {
        background: #FFF;
    }

    .company-table table tbody tr td:nth-of-type(6):after {
        content: '';
        position: absolute;
        top: 2px;
        right: 53px;
        background-image: url(../src/assets/image/vector-right-icon.png);
        height: 12px;
        width: 7.41px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .company-table table tbody tr {
        background: #faf9fd !important;
        height: 110px;
    }

    /* section.footer {
        position: absolute;
        bottom: 0;
    } */

    .table-responsive mat-row mat-cell {
        width: 100%;
    }

    .mat-card {
        padding: 11px 8px !important;
    }

    .ready-to-pic_table table tbody tr td:nth-child(1) {
        display: none !important;
    }

    .ready-to-pic_table table tbody tr {
        display: flex;
        flex-direction: column;
    }

    .ready-to-pic_table table tbody tr td:nth-child(2) {
        order: 2;
    }

    .ready-to-pic_table table tbody tr td:nth-child(3) {
        order: 3;
    }

    .ready-to-pic_table table tbody tr td:nth-child(4) {
        order: 4;
    }

    .ready-to-pic_table table tbody tr td:nth-child(5) {
        order: 5;
    }

    .ready-to-pic_table table tbody tr td:nth-child(6) {
        order: 6;
    }

    .ready-to-pic_table table tbody tr td:nth-child(7) {
        order: 7;
    }

    .ready-to-pic_table table tbody tr td:nth-child(8) {
        order: 9;
    }

    .ready-to-pic_table table tbody tr td:nth-child(9) {
        order: 1;
        position: relative;
    }

    .ready-to-pic_table table tbody tr td:nth-child(9):before {
        content: '';
        position: absolute;
        top: 13px;
        right: 16px;
        background-image: url(../src/assets/image/more.png);
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .picklist-table tr td:nth-child(7) {
        width: 100% !important;
    }

    .warehouse-row .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch {
        max-width: calc(100% - 40px) !important;
    }

    td.sorting_1 img {
        height: auto;
        width: 25px;
    }

    .header-right .nav-item {
        padding: 0;
    }

    .inventory-table-new tr {
        height: auto;
    }

    .inventory-table-new td {
        width: 100%;
    }

    .company-card .mat-mdc-card-content {
        padding: 20px 15px 10px 15px !important;
    }

    .table-responsive mat-row {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid silver !important;
        padding: 10px 0px;
    }


    .table-responsive mat-row:nth-child(2n+0) {
        border-right: none !important;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 0;
    }

    .select-add-field .mat-mdc-form-field-infix {
        width: auto !important;
    }

    .table-material tbody tr td {
        width: 100%;
    }

    mat-label {
        font-size: 13px;
    }

    .mat-mdc-select span {
        font-size: 14px;
    }

    input {
        font-size: 14px !important;
    }
}

@media screen and (min-width: 768px) {
    .desktop-table {
        display: block;
    }

    .mat-expansion-panel-header {
        height: auto !important;
    }

}

/* ----------------------------------- */
.border-back .mat-mdc-card-header-text {
    width: 100%;
}

.mat-mdc-form-field-infix {
    display: flex;
}

.mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}

/* Modified class for Ng16 by Shail */
.modal-backdrop {
    z-index: 999 !important;
}

.company-dropdowm-height .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 28px;
}

.company-dropdowm-height .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    height: 40px !important;
}

.company-dropdowm-height .mat-mdc-form-field-infix {
    padding: 7px !important;
}

.email-configure-height .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
    height: 36px !important;
}

.email-configure-height .mat-mdc-form-field-infix {
    padding: 7px !important;
}

.mdc-checkbox {
    height: 24px !important;
}

/* checkbox-change css */
.mat-checkbox-change .mdc-checkbox__background {
    left: 0 !important;
}

.mat-checkbox-change .mdc-checkbox {
    padding-left: 0 !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
    display: none !important;
}

.mat-mdc-select-panel {
    padding: 0px !important;
}

.customer-roles-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 28px;
}

.customer-roles-select .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    height: 30px !important;
}

.customer-roles-select .mat-mdc-form-field-infix {
    padding: 7px !important;
}

.customer-roles-select .mat-mdc-form-field span {
    font-size: 14px;
}

/* --------------------------Landscape for iPad Air------------------------------ */
@media all and (min-width: 1024px) and (max-width: 1366px) {
    .autocomplete-container .input-container input {
        width: 100% !important;
    }

    .inventory-header {
        display: none;
    }

    .comapny-dropdown-height .menu-toggle .sidebar-cross-icon {
        display: none;
    }

    .inventory-table mat-cell,
    mat-header-cell,
    mat-footer-cell {
        min-height: unset;
    }

    .material-inventory-row {
        padding: 5px;
        border: 1px solid silver;
    }

    .input-container input {
        display: none !important;
    }
}

@media (min-width: 1200px) and (max-width:1440px) {
    .change-column-width {
        flex: 0 0 99.6% !important;
        max-width: 100% !important;
    }
}

@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation:landscape) {
    .col-xl-6.col-lg-12.col-md-6.col-12.pd-left.order-2-ipad {
        max-width: 60% !important;
        margin-bottom: 16px;
    }

    th.mat-mdc-header-cell.mdc-data-table__header-cell.cdk-header-cell.createpicking-button.cdk-column-CreatePickingTicket.mat-column-CreatePickingTicket.ng-star-inserted.mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-top {
        position: absolute !important;
        right: 13px;
        top: 8% !important;
        background: transparent !important;
        border: 0;
    }

    .createpicking-button .mdc-data-table__cell,
    .mdc-data-table__header-cell {
        padding: 0 3px 0 3px !important;
    }

    table tbody tr td:last-child {
        display: none;
    }

    td.mat-mdc-cell.mdc-data-table__cell.cdk-cell.cdk-column-CreatePickingTicket.mat-column-CreatePickingTicket.ng-star-inserted {
        display: none;
    }

    .inventory-header {
        display: none;
    }

    .inventory-table .inventory-mat-cell:before,
    .inventory-table-new td::before {
        content: attr(data-label);
        float: left;
        font-weight: 500;
        margin-right: 40px;
        max-width: 110px;
        width: 100%;
        padding: 4px 0px;
    }

    .table-inventory mat-row,
    mat-footer-row {
        min-height: 30px;
        /* border-bottom: 1px solid silver !important; */
    }

    .inventory-table-new thead {
        display: none;
    }

    .inventory-table-new tr {
        height: 208px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid silver;
        align-items: center;
    }

    .inventory-table .inventory-mat-cell:before,
    .inventory-table-new td::before {
        content: attr(data-label);
        float: left;
        font-weight: 500;
        margin-right: 40px;
        width: 110px;
        padding: 4px 0px;
    }

    .inventory-table-new tr td {
        width: 49%;
        border: 0px;
    }

    .inventory-row .col-xl-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.part-category-width {
    max-width: 387px;
    width: 100%;
}

.mdc-linear-progress__bar.mdc-linear-progress__primary-bar {
    background-color: #005FAE;
}

.swal2-container {
    z-index: 9999 !important;
}

.mat-expansion-panel-header {
    height: auto !important;
}